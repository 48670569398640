import { Capitalize } from "../../lib/textHelps";
import { ProductItemComponentProps } from "../../types/productItemComponentProps";
import Icon from "react-icons-kit";
import { copy } from "react-icons-kit/fa/copy";

export default function ProductItemComponent(
  props: ProductItemComponentProps
): JSX.Element {
  if (typeof props.useSearchLayout !== "undefined" && props.useSearchLayout) {
    return (
      <div className={`mt-3 ${props.additionalClasses ?? ""}`}>
        <div className="w-full text-3xl text-center mb-2 font-bold">
          {props.product?.Name} by {props.product.xp?.Brand}
        </div>
        <img
          src={
            typeof props.product?.xp?.Images !== "undefined" &&
            props.product?.xp?.Images?.length > 0
              ? props.product?.xp?.Images[0]
              : ""
          }
          alt={props.product?.Name}
        ></img>
        <ul className="list-outside mt-1 ml-1">
          <li className="grid grid-cols-12 text-xl mb-1">
            <span className="text-grey-400 col-span-3 text-right">Name:</span>
            <span className="col-span-9 ml-1">{props.product.Name}</span>
          </li>
          <li className="grid grid-cols-12 text-xl mb-1">
            <span className="text-grey-400 col-span-3 text-right">Brand:</span>
            <span className="col-span-9 ml-1 text-blue-100 hover:underline"><button type="button"
                        className="cursor-pointer block"
                        onClick={() => {
                          if (typeof props.setSearchText !== "undefined") {
                            props.setSearchText(props.product.xp?.Brand??"");
                          }
                        }}>{Capitalize(props.product.xp?.Brand??"")}</button></span>
          </li>
          <li className="grid grid-cols-12 text-xl mb-1">
            <span className="text-grey-400 col-span-3 text-right">SKU:</span>
            <span className="col-span-9 ml-1">
              {props.product.ID}
              <Icon
                icon={copy}
                size={14}
                className="float-right cursor-pointer hover:text-blue-100"
                onClick={() => {
                  navigator.clipboard.writeText(props.product.ID ?? "");
                }}
              ></Icon>
            </span>
          </li>
          <li className="grid grid-cols-12 text-xl mb-1">
            <span className="text-grey-400 col-span-3 text-right">
              Description:
            </span>
            <span className="col-span-9 ml-1">
              {props.product.xp?.Details ?? props.product.Description}
            </span>
          </li>
          <li className="grid grid-cols-12 text-xl mb-1">
            <span className="text-grey-400 col-span-3 text-right">Style:</span>
            <span className="col-span-9 ml-1">
              {props.product.xp?.StyleNumber}
            </span>
          </li>
          {typeof props.specs !== "undefined" &&
            props.specs !== null &&
            props.specs?.length > 0 &&
            props.specs?.map((s, i) => {
              return (
                <li className="grid grid-cols-12 text-xl mb-1" key={i}>
                  <span className="text-grey-400 col-span-3 text-right">
                    {s.Name}:
                  </span>
                  <span className="col-span-9 ml-1">
                    {s?.Options?.map((o) => {
                      return Capitalize(o.Value ?? "");
                    })
                      .sort((a, b) => {
                        if (isNaN(+a) && isNaN(+b)) {
                          if (+a < +b) return -1;
                          if (+a > +b) return 1;
                          return 0;
                        } else {
                          return a.localeCompare(b);
                        }
                      })
                      .join(",") ?? ""}
                  </span>
                </li>
              );
            })}
          {typeof props.product.xp?.GiftFinder?.Occaision !== "undefined" &&
            props.product.xp?.GiftFinder?.Occaision.length > 0 && (
              <li className="grid grid-cols-12 text-xl mb-1">
                <span className="text-grey-400 col-span-3 text-right">
                  Occasions:
                </span>
                <span className="col-span-9 ml-1  text-blue-100 hover:underline">
                  {props.product.xp?.GiftFinder?.Occaision.sort((a, b) => {
                    if (isNaN(+a) && isNaN(+b)) {
                      if (+a < +b) return -1;
                      if (+a > +b) return 1;
                      return 0;
                    } else {
                      return a.localeCompare(b);
                    }
                  }).map((o, i) => {
                    return (
                      <button
                        key={i}
                        title={o}
                        type="button"
                        className="cursor-pointer block"
                        onClick={() => {
                          if (typeof props.setSearchText !== "undefined") {
                            props.setSearchText(o);
                          }
                        }}
                      >
                        {Capitalize(o ?? "")}
                      </button>
                    );
                  }) ?? ""}
                </span>
              </li>
            )}
          {typeof props.product.xp?.GiftFinder?.Personality !== "undefined" &&
            props.product.xp?.GiftFinder?.Personality.length > 0 && (
              <li className="grid grid-cols-12 text-xl mb-1">
                <span className="text-grey-400 col-span-3 text-right">
                  Personality:
                </span>
                <span className="col-span-9 ml-1 text-blue-100 hover:underline">
                  {props.product.xp?.GiftFinder?.Personality.sort((a, b) => {
                    if (isNaN(+a) && isNaN(+b)) {
                      if (+a < +b) return -1;
                      if (+a > +b) return 1;
                      return 0;
                    } else {
                      return a.localeCompare(b);
                    }
                  }).map((o, i) => {
                    return (
                      <button
                        key={i}
                        title={o}
                        type="button"
                        className="cursor-pointer block"
                        onClick={() => {
                          if (typeof props.setSearchText !== "undefined") {
                            props.setSearchText(o);
                          }
                        }}
                      >
                        {Capitalize(o ?? "")}
                      </button>
                    );
                  }) ?? ""}
                </span>
              </li>
            )}
          {typeof props.product.xp?.GiftFinder?.Recipient !== "undefined" &&
            props.product.xp?.GiftFinder?.Recipient.length > 0 && (
              <li className="grid grid-cols-12 text-xl mb-1">
                <span className="text-grey-400 col-span-3 text-right">
                  Recipient:
                </span>
                <span className="col-span-9 ml-1  text-blue-100 hover:underline">
                  {props.product.xp?.GiftFinder?.Recipient.sort((a, b) => {
                    if (isNaN(+a) && isNaN(+b)) {
                      if (+a < +b) return -1;
                      if (+a > +b) return 1;
                      return 0;
                    } else {
                      return a.localeCompare(b);
                    }
                  }).map((o, i) => {
                    return (
                      <button
                        key={i}
                        type="button"
                        className="cursor-pointer block"
                        title={o}
                        onClick={() => {
                          if (typeof props.setSearchText !== "undefined") {
                            props.setSearchText(o);
                          }
                        }}
                      >
                        {Capitalize(o ?? "")}
                      </button>
                    );
                  }) ?? ""}
                </span>
              </li>
            )}
          {typeof props.product.xp?.SimilarItems !== "undefined" &&
            props.product.xp?.SimilarItems.length > 0 && (
              <li className="grid grid-cols-12 text-xl mb-1">
                <span className="text-grey-400 col-span-3 text-right">
                  Similar:
                </span>
                <span className="col-span-9 ml-1  text-blue-100 hover:underline">
                  {props.product.xp?.SimilarItems.sort((a, b) => {
                    if (isNaN(+a) && isNaN(+b)) {
                      if (+a < +b) return -1;
                      if (+a > +b) return 1;
                      return 0;
                    } else {
                      return a.localeCompare(b);
                    }
                  }).map((o, i) => {
                    return (
                      <button
                        key={i}
                        type="button"
                        className="cursor-pointer block"
                        title={o}
                        onClick={() => {
                          if (typeof props.setSearchText !== "undefined") {
                            props.setSearchText(o);
                          }
                        }}
                      >
                        {Capitalize(o ?? "")}
                      </button>
                    );
                  }) ?? ""}
                </span>
              </li>
            )}
          {typeof props.product.xp?.Suggestions !== "undefined" &&
            props.product.xp?.Suggestions.length > 0 && (
              <li className="grid grid-cols-12 text-xl mb-1">
                <span className="text-grey-400 col-span-3 text-right">
                  Suggestions:
                </span>
                <span className="col-span-9 ml-1  text-blue-100 hover:underline">
                  {props.product.xp?.Suggestions.sort((a, b) => {
                    if (isNaN(+a) && isNaN(+b)) {
                      if (+a < +b) return -1;
                      if (+a > +b) return 1;
                      return 0;
                    } else {
                      return a.localeCompare(b);
                    }
                  }).map((o, i) => {
                    return (
                      <button
                        key={i}
                        type="button"
                        className="cursor-pointer block"
                        title={o}
                        onClick={() => {
                          if (typeof props.setSearchText !== "undefined") {
                            props.setSearchText(o);
                          }
                        }}
                      >
                        {Capitalize(o ?? "")}
                      </button>
                    );
                  }) ?? ""}
                </span>
              </li>
            )}
        </ul>
      </div>
    );
  }
  return (
    <div className={`mt-3 grid grid-cols-12 ${props.additionalClasses ?? ""}`}>
      <div className="col-span-2 w-full"> </div>
      <div className="col-span-4">
        <img
          src={
            typeof props.product?.xp?.Thumbnails !== "undefined" &&
            props.product?.xp?.Thumbnails?.length > 0
              ? props.product?.xp?.Thumbnails[0]
              : ""
          }
          alt={props.product?.Name}
        ></img>
      </div>
      <div className={`${"col-span-6"}`}>
        <div className="text-xl ml-1">{props.product?.Description}</div>
        <ul className="list-outside mt-1 ml-1">
          <li className="grid grid-cols-12">
            <span className="text-grey-400 col-span-2 text-right">SKU:</span>
            <span className="col-span-10">{props.product.ID}</span>
          </li>
          <li className="grid grid-cols-12">
            <span className="text-grey-400 col-span-2 text-right">UPC:</span>
            <span className="col-span-10">
              {props.product?.xp?.StyleNumber}
            </span>
          </li>
          <li className="grid grid-cols-12">
            <span className="text-grey-400 col-span-2 text-right">Brand:</span>
            <span className="col-span-10">{props.product?.xp?.Brand}</span>
          </li>
          {typeof props.specs !== "undefined" &&
            props.specs !== null &&
            props.specs?.length > 0 &&
            props.specs?.map((s, i) => {
              return (
                <li className="grid grid-cols-12" key={i}>
                  <span className="text-grey-400 col-span-2 text-right">
                    {s.Name}:
                  </span>
                  <span className="col-span-10">
                    {s?.Options?.map((o) => {
                      return Capitalize(o.Value ?? "");
                    })
                      .sort((a, b) => {
                        if (isNaN(+a) && isNaN(+b)) {
                          if (+a < +b) return -1;
                          if (+a > +b) return 1;
                          return 0;
                        } else {
                          return a.localeCompare(b);
                        }
                      })
                      .join(",") ?? ""}
                  </span>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
