/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Header from "../../header";
import { Product, Spec } from "ordercloud-javascript-sdk";
import Footer from "../../footer";
import ProductItemComponent from "../../shared/productItemComponent";
import Icon from "react-icons-kit";
import { Html5QrcodeResult, Html5QrcodeScanner } from "html5-qrcode";
import { Html5QrcodeError } from "html5-qrcode/esm/core";
import Modal from "react-modal";
import {
  GetProductSpecs,
  GetProductsBySearchWithoutSupplierWithCallback,
} from "../../../lib/productHelper";
import { confirmAlert } from "react-confirm-alert";
import { barcode } from "react-icons-kit/fa/barcode";
import { close } from "react-icons-kit/fa/close";
import Title from "../../shared/title";

export default function ProductSearch(): JSX.Element {
  const customStyles = {
    content: {
      top: "auto",
      left: "auto",
      right: "auto",
      bottom: "auto",
      width: "100%",
      height: "100%",
    },
    overlay: { zIndex: 1000 },
  };

  const [searchText, setSearchText] = useState<string>("");
  const [selectValue, setSelectValue] = useState<string | null>(null);
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState<Array<Product<any>> | null>(null);
  const [specs, setSpecs] = useState<Array<Spec<any, any>> | null>(null);
  const [, setSelectedSpecs] = useState<
    Array<{ specId: string; optionId: string }>
  >(Array<{ specId: string; optionId: string }>());
  const [selectedProduct, setSelectedProduct] = useState<Product<any> | null>(
    null
  );
  const [scanner, setScanner] = useState<Html5QrcodeScanner | null>(null);
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);

  const handleSearch = () => {
    setProducts(null);
    setSelectValue(null);
    setSelectedProduct(null);
    setSpecs(null);
    setSelectedSpecs(Array<{ specId: string; optionId: string }>());
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    const searchProducts = async (): Promise<void> => {
      if (search.length <= 3) {
        return;
      }
      await GetProductsBySearchWithoutSupplierWithCallback(
        search,
        searchCallback,
        searchFailureCallback
      );
    };

    const searchCallback = (p: Array<Product<any>>) => {
      setProducts(p);
    };

    const searchFailureCallback = () => {
      confirmAlert({
        title: "Search failed to return any results.",
        buttons: [
          {
            label: "Ok",
            onClick: async () => {},
          },
        ],
      });
    };

    const getProductSpecs = async () => {
      await GetProductSpecs(
        selectedProduct?.ID ?? "",
        specCallBack,
        specsFailureCallback
      );
    };

    const specCallBack = (s: Array<Spec<any, any>>) => {
      setSpecs(s);
      setSelectedSpecs(
        s
          .filter((spec) => {
            return (
              typeof spec?.Options !== "undefined" && spec?.Options.length > 0
            );
          })
          .map((spec) => {
            return {
              optionId:
                typeof spec.Options !== "undefined"
                  ? spec.Options[0]?.ID ?? ""
                  : "",
              specId: spec.ID ?? "",
            };
          })
      );
    };

    const specsFailureCallback = () => {};

    if (search.length > 3) {
      if (products === null) {
        searchProducts();
      }
    } else {
      setProducts(null);
      setSelectValue(null);
      setSelectedProduct(null);

      setSpecs(null);
      setSelectedSpecs(Array<{ specId: string; optionId: string }>());
    }

    if (selectedProduct !== null) {
      getProductSpecs();
    }
  }, [products, search, selectedProduct]);

  if (
    selectValue === null &&
    products !== null &&
    products.length === 1 &&
    products[0] !== null
  ) {
    setSelectValue(products[0]?.ID ?? "");
    setSelectedProduct(products[0]);
    setSpecs(null);
    setSelectedSpecs(Array<{ specId: string; optionId: string }>());
  }

  if(searchText!==""){
    setSearch(searchText);
    handleSearch();
    setSearchText("");
  }

  return (
    <>
      <div className="flex min-h-full flex-col">
        <Header title="Product Search" />
        <div className="ml-2 min-h-fit mb-4 mr-2">
          <Title title="Search for a Product"/>
          <div className="flex">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-1 text-grey-400 leading-tight focus:outline-none focus:shadow-outline max-h-2 mt-1"
              id="Search for a product"
              type="text"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              value={search}
              onKeyDown={handleKeyDown}
            />
            <span
              className="flex justify-around items-center"
              onClick={() => {
                setSearch("");
                setSelectValue(null);
                setSelectedProduct(null);
                setSpecs(null);
                setSelectedSpecs(Array<{ specId: string; optionId: string }>());
                setProducts(null);
                setIsOpen(true);
              }}
            >
              <Icon
                className="absolute mr-5 mt-1 cursor-pointer"
                icon={barcode}
                size={14}
              />
            </span>
          </div>
          {search !== "" && products !== null && products.length > 0 && (
            <>
              <div className="mt-2"></div>
              <label
                htmlFor="productSelect"
                className="block text-grey-400 text-sm font-bold mb-0"
              >
                Select a product ({products.length}):
              </label>
              <select
                id="productSelect"
                name="productSelect"
                className="bg-white border-b px-0 text-grey-400 focus:outline-none focus:shadow-outline block w-full p-2.5 dark:placeholder-black dark:black h-3 pl-0 mt-1"
                onChange={async (value) => {
                  if (value.target.value === "") {
                    setSelectValue("");
                    setSelectedProduct(null);
                    setSpecs(null);
                    setSelectedSpecs(
                      Array<{ specId: string; optionId: string }>()
                    );
                  } else {
                    const prod =
                      products.find((p) => {
                        return p.ID === value.target.value;
                      }) ?? null;
                    setSelectedProduct(prod);
                    setSelectValue(value.target.value);
                    setSpecs(null);
                    setSelectedSpecs(
                      Array<{ specId: string; optionId: string }>()
                    );
                  }
                }}
                value={selectValue ?? ""}
              >
                <option value="">Select a Product</option>
                {products.map((p, i) => {
                  return (
                    <option value={p.ID} key={i}>
                      {p.Name}
                    </option>
                  );
                })}
              </select>
            </>
          )}
          {search !== "" && products !== null && products.length === 0 && (
            <>
              <img src={"/assets/img/empty.gif"} alt="not found"></img>
              <div className="w-full text-center text-lg">
                No Products Found.
              </div>
            </>
          )}

          {selectedProduct !== null && specs !== null && (
            <ProductItemComponent product={selectedProduct} specs={specs} useSearchLayout={true} setSearchText={setSearchText}/>
          )}
        </div>
        <Footer selected="Search" />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => {
          if (scanner === null) {
            const s = new Html5QrcodeScanner(
              "qr-reader",
              { fps: 10, qrbox: 250 },
              true
            );

            s.render(
              (decodedText: string, result: Html5QrcodeResult) => {
                setSearch(decodedText);
                setIsOpen(false);
              },
              (errorMessage: string, error: Html5QrcodeError) => {}
            );

            setScanner(s);
          }
        }}
        onAfterClose={() => {
          if (scanner !== null) {
            scanner.clear();
            setScanner(null);
          }
        }}
        onRequestClose={closeModal}
        contentLabel="Bar Code Scanner"
        style={customStyles}
      >
        <div
          className="mb-3 "
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <Icon
            className="cursor-pointer absolute right-1"
            icon={close}
            size={14}
          />
        </div>
        <div className="">
          <div id="qr-reader"></div>
        </div>
      </Modal>
    </>
  );
}
