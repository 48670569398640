import { StoreNameProps as TitleProps } from "../../types/titleProps";

export default function Title(props: TitleProps): JSX.Element {
  const subTitleMargin = props.subTitleMargin ?? "mt-1";

  return (
    <>
      {typeof props.title !== "undefined" && (
        <h1 className="font-bold text-2xl mb-3 mt-1">{props.title}</h1>
      )}
      {typeof props.subTitle !== "undefined" && (
        <h2 className={`font-bold text-xl mb-1 ${subTitleMargin}`}>
          {props.subTitle}
        </h2>
      )}
    </>
  );
}
