import { CompletedProgressProps } from "../../types/completedProgressProps";

export default function CompletedProgress(
  props: CompletedProgressProps
): JSX.Element {
  let percentage = 0;
  if (props.maxQuantity > 0) {
    percentage = Math.round((props.pickedQuantity / props.maxQuantity) * 100);
  }
  return (
    <div className="w-full bg-black text-white">
      <div className="ml-3 mr-2 w-4/5 h-3 bg-black rounded-full dark:bg-white">
        <div
          className="h-3 bg-blue-200 rounded-full dark:bg-blue-200"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <span className="float-right relative -top-2 pr-3 text-xl">
        {props.pickedQuantity}/{props.maxQuantity}
      </span>
    </div>
  );
}
