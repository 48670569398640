import Icon from "react-icons-kit";
import { phone } from "react-icons-kit/feather/phone";
import { ClientComponentProps } from "../../types/clientComponentProps";

export default function ClientComponent(props:ClientComponentProps):JSX.Element{
    return(
        <div className="mx-auto px-1 z-50 justify-between py-1 bg-blue-200 text-white grid grid-cols-12 min-w-full text-xl mt-3 ">
          <div className="col-span-1">
            <Icon className="" icon={phone} size={14} />
          </div>
          <div className="col-span-6">
            <a href={`tel:${props.phone}`} className="w-full">
              Call Client: {props.phone}
            </a>
          </div>
          <div className="col-span-1 text-center ">|</div>
          <div className="col-span-4">
            {props.firstName} {props.lastName}
          </div>
        </div>
    )
}