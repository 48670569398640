import {
  Filters,
  LineItem,
  LineItems,
  MeUser,
  Order,
  Orders,
  Payment,
  Payments,
  Shipment,
} from "ordercloud-javascript-sdk";
import { TabType } from "../types/tabButtonProps";
import {
  DeliveryTypes,
  FulfillmentTypes,
  LineItemXp,
  OrderXp,
  PaymentXp,
  ShippingXp,
  VariantXp,
} from "../types/OrderCloud/xp";
import moment from "moment";

export const SaveNoteToOrderXp = async (
  newNote: string,
  orderId: string,
  callback?: (order: Order<OrderXp, any, any>) => void,
  logging: boolean = false
) => {
  await Orders.Get("All", orderId ?? "")
    .then(async (result) => {
      let xp = result.xp;

      if (typeof xp.Notes === "undefined" || xp.Notes === null) {
        xp.Notes = Array<string>();
      }

      xp.Notes.push(newNote);

      await Orders.Patch("All", orderId ?? "", { xp: xp })
        .then((order) => {
          if (logging) {
            console.log(order);
          }
          if (typeof callback !== "undefined") {
            callback(order);
          } else {
            console.log(
              "Invalid or no callback provided for SaveNoteToOrderXp"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    })
    .catch((err) => {
      console.log(err);
    });
};

export const GetOrderByIdWithCallback = async (
  orderId: string | undefined,
  callback: (order: Order<OrderXp, any, any>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Orders.Get("All", orderId ?? "")
    .then(async (order) => {
      if (order === null) {
        if (typeof failureCallback !== "undefined") {
          failureCallback();
        }
      }
      if (logging) {
        console.log(order);
      }
      if (typeof callback !== "undefined") {
        callback(order);
      } else {
        console.log(
          "Invalid or no callback provided for GetOrderByIdWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
      if (typeof failureCallback !== "undefined") {
        failureCallback();
      }
    });
};

export const GetOrderReadyToPickWithCallback = async (
  id: string,
  mineOnly: boolean,
  search: string | null,
  me: MeUser<any> | null,
  callback: (orders: Array<Order<OrderXp, any, any>>) => void,
  logging: boolean = false
) => {
  let filters: Filters = {
    "xp.Status": ["InProgress"],
  };

  if (mineOnly) {
    filters = {
      "xp.Status": ["InProgress"],
    };
  }

  let params: any = {
    supplierID: id,
    filters: filters,
  };
  if (search !== null && search !== "") {
    params = {
      supplierID: id,
      search: search,
      searchOn: [
        "ID",
        "Subtotal",
        "FromUser.FirstName",
        "FromUser.LastName",
        "FromUser.Email",
        "FromUser.Phone",
        "BillingAddress.FirstName",
        "BillingAddress.LastName",
        "BillingAddress.Phone",
        "xp.Status",
        "xp.AssignedTo",
      ],
      filters: filters,
    };
  }

  await Orders.List("All", params)
    .then((orders) => {
      if (logging) {
        console.log(orders);
      }
      if (typeof callback !== "undefined") {
        callback(orders.Items);
      } else {
        console.log(
          "Invalid or no callback provided for GetOrderByIDAndTabWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const GetOrderOutForDeliveryWithCallback = async (
  id: string,
  mineOnly: boolean,
  search: string | null,
  me: MeUser<any> | null,
  callback: (orders: Array<Order<OrderXp, any, any>>) => void,
  logging: boolean = false
) => {
  let filters: Filters = {
    "xp.Status": ["Picked"],
    "xp.FulfillmentType": ["Delivery"],
    "xp.Delivery": ["OutForDelivery"],
  };

  if (mineOnly) {
    filters = {
      "xp.Status": ["Picked"],
      "xp.FulfillmentType": ["Delivery"],
      "xp.Delivery": ["OutForDelivery"],
      "xp.Assignment.AssignedTo": [`${me?.ID ?? ""}`],
    };
  }

  let params: any = {
    supplierID: id,
    filters: filters,
  };
  if (search !== null && search !== "") {
    params = {
      supplierID: id,
      search: search,
      searchOn: [
        "ID",
        "Subtotal",
        "FromUser.FirstName",
        "FromUser.LastName",
        "FromUser.Email",
        "FromUser.Phone",
        "BillingAddress.FirstName",
        "BillingAddress.LastName",
        "BillingAddress.Phone",
        "xp.Status",
        "xp.AssignedTo",
      ],
      filters: filters,
    };
  }

  await Orders.List("All", params)
    .then((orders) => {
      if (logging) {
        console.log(orders);
      }
      if (typeof callback !== "undefined") {
        callback(orders.Items);
      } else {
        console.log(
          "Invalid or no callback provided for GetOrderByIDAndTabWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const GetOrderByIDAndTabWithCallback = async (
  id: string,
  tab: TabType,
  mineOnly: boolean,
  search: string | null,
  me: MeUser<any> | null,
  callback: (orders: Array<Order<OrderXp, any, any>>) => void,
  logging: boolean = false
) => {
  let filters: Filters = {
    "xp.Status": ["!OnHold", "!Completed", "!Cancelled"],
  };
  switch (tab) {
    case "All":
      if (mineOnly) {
        filters = {
          "xp.Status": ["!OnHold", "!Completed", "!Cancelled"],
          "xp.Assignment.AssignedTo": [`${me?.ID ?? ""}`],
        };
      } else {
        filters = { "xp.Status": ["!OnHold", "!Completed", "!Cancelled"] };
      }
      break;
    case "Assigned":
      if (mineOnly) {
        filters = {
          "xp.Status": ["!Completed", "!Cancelled"],
          "xp.Assignment.AssignedTo": [`${me?.ID ?? ""}`],
        };
      } else {
        filters = {
          "xp.Status": ["!Completed", "!Cancelled"],
          "xp.Assignment.AssignedTo": "*",
        };
      }
      break;
    case "InProgress":
      if (mineOnly) {
        filters = {
          "xp.Status": ["InProgress"],
          "xp.Assignment.AssignedTo": [`${me?.ID ?? ""}`],
        };
      } else {
        filters = { "xp.Status": ["InProgress"] };
      }
      break;
    case "OnHold":
      if (mineOnly) {
        filters = {
          "xp.Status": ["OnHold"],
          "xp.Assignment.AssignedTo": [`${me?.ID ?? ""}`],
        };
      } else {
        filters = { "xp.Status": ["OnHold"] };
      }
      break;
    case "Completed":
      if (mineOnly) {
        filters = {
          "xp.Status": ["Completed|Cancelled"],
          "xp.Assignment.AssignedTo": [`${me?.ID ?? ""}`],
        };
      } else {
        filters = { "xp.Status": ["Completed|Cancelled"] };
      }
      break;
  }

  let params: any = {
    supplierID: id,
    filters: filters,
  };
  if (search !== null && search !== "") {
    params = {
      supplierID: id,
      search: search,
      searchOn: [
        "ID",
        "Subtotal",
        "FromUser.FirstName",
        "FromUser.LastName",
        "FromUser.Email",
        "FromUser.Phone",
        "BillingAddress.FirstName",
        "BillingAddress.LastName",
        "BillingAddress.Phone",
        "xp.Status",
        "xp.AssignedTo",
      ],
      filters: filters,
    };
  }

  await Orders.List("All", params)
    .then((orders) => {
      if (logging) {
        console.log(orders);
      }
      if (typeof callback !== "undefined") {
        callback(orders.Items);
      } else {
        console.log(
          "Invalid or no callback provided for GetOrderByIDAndTabWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const GetNotStartedOrdersWithCallback = async (
  id: string,
  callback: (orders: Array<Order<OrderXp, any, any>>) => void,
  logging: boolean = false
) => {
  let filters: Filters = {
    "xp.Status": ["NotStarted|!*"],
  };

  let params: any = {
    supplierID: id,
    filters: filters,
  };

  await Orders.List("All", params)
    .then((orders) => {
      if (logging) {
        console.log(orders);
      }
      if (typeof callback !== "undefined") {
        callback(orders.Items);
      } else {
        console.log(
          "Invalid or no callback provided for GetNotStartedOrdersWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const GetMyOrdersWithCallback = async (
  id: string,
  me: MeUser<any> | null,
  callback: (orders: Array<Order<OrderXp, any, any>>) => void,
  logging: boolean = false
) => {
  let filters: Filters = {
    "xp.Status": ["NotStarted|!*|InProgress|Picked"],
    "xp.Assignment.AssignedTo": [`${me?.ID ?? ""}`],
  };

  let params: any = {
    supplierID: id,
    filters: filters,
  };

  await Orders.List("All", params)
    .then((orders) => {
      if (logging) {
        console.log(orders);
      }
      if (typeof callback !== "undefined") {
        callback(orders.Items);
      } else {
        console.log(
          "Invalid or no callback provided for GetMyOrdersWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const GetOrderShipmentsWithCallback = async (
  orderId: string,
  callback: (
    orderId: string,
    shipments: Array<Shipment<any, any, ShippingXp>>
  ) => void,
  logging: boolean = false
) => {
  await Orders.ListShipments("All", orderId)
    .then((shipments) => {
      if (logging) {
        console.log(shipments);
      }
      if (typeof callback !== "undefined") {
        callback(orderId, shipments.Items);
      } else {
        console.log(
          "Invalid or no callback provided for GetOrderShipmentsWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const GetOrderLineItemWithCallback = async (
  lineItemId: string,
  orderId: string,
  callback: (lineItem: LineItem<LineItemXp, any, VariantXp>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await LineItems.Get("All", orderId, lineItemId)
    .then((lineItem) => {
      if (logging) {
        console.log(lineItem);
      }
      if (lineItem === null) {
        if (typeof failureCallback !== "undefined") {
          failureCallback();
        }
      }
      if (typeof callback !== "undefined") {
        callback(lineItem);
      } else {
        console.log(
          "Invalid or no callback provided for GetOrderLineItemsWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
      if (typeof failureCallback !== "undefined") {
        failureCallback();
      }
    });
};

export const GetOrderLineItemsWithCallback = async (
  orderId: string,
  callback: (
    orderId: string,
    lineItems: Array<LineItem<LineItemXp, any, VariantXp>>
  ) => void,
  logging: boolean = false
) => {
  await LineItems.List("All", orderId)
    .then((lineItems) => {
      if (logging) {
        console.log(lineItems);
      }
      if (typeof callback !== "undefined") {
        callback(orderId, lineItems.Items);
      } else {
        console.log(
          "Invalid or no callback provided for GetOrderLineItemsWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const SaveAssignedUserWithNote = async (
  order: Order<OrderXp, any, any>,
  userId: string | undefined,
  fullName: string | undefined,
  lockedOrders: Array<string>,
  orders: Array<Order<OrderXp, any, any>> | null,
  me: MeUser<any> | null,
  setLockedOrders: React.Dispatch<React.SetStateAction<string[]>>,
  setOrders: React.Dispatch<
    React.SetStateAction<Array<Order<OrderXp, any, any>> | null>
  >,
  logging: boolean = false
) => {
  let assigning = true;
  let xp: OrderXp = {
    Status: "NotStarted",
    Assignment: { AssignedTo: null, FullName: "" },
    Notes: order.xp?.Notes,
    FulfillmentType: order.xp?.FulfillmentType,
    Delivery: order.xp?.Delivery,
  };
  if (typeof userId !== "undefined") {
    xp = {
      Status:
        order.xp?.Status !== "Cancelled" &&
        order.xp?.Status !== "Completed" &&
        order.xp?.Status !== "OnHold" &&
        order.xp?.Status !== "Picked"
          ? "InProgress"
          : order.xp?.Status,
      Assignment: { AssignedTo: userId, FullName: fullName ?? "" },
      Notes: order.xp?.Notes,
      FulfillmentType: order.xp?.FulfillmentType,
      Delivery: order.xp?.Delivery,
    };
  } else {
    assigning = false;
  }

  if (
    lockedOrders.indexOf(order.ID ?? "") === -1 &&
    typeof order.ID !== "undefined"
  ) {
    const locked = lockedOrders;
    locked.push(order.ID);
    setLockedOrders(locked);
  }

  await Orders.Patch("All", order.ID ?? "", { xp: xp })
    .then(async (result) => {
      if (logging) {
        console.log(result);
      }
      const locked = lockedOrders.filter((lo) => {
        return lo !== order.ID;
      });

      const newOrders =
        orders?.map((o) => {
          return o.ID === order.ID ? result : o;
        }) ?? Array<Order<OrderXp, any, any>>();
      setOrders(newOrders);
      setLockedOrders(locked);

      await SaveNoteToOrderXp(
        `Order ${assigning ? "was assigned to" : "was unassigned from"} ${
          me?.FirstName
        } ${me?.LastName} on ${moment().format("MMMM Do YYYY, h:mm:ss a")} `,
        result.ID
      );
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {});
};

export const PickedOrder = async (
  orderId: string | null,
  me: MeUser<any> | null,
  callback: (order: Order<OrderXp, any, any>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Orders.Get("All", orderId ?? "")
    .then(async (result) => {
      let xp = result.xp;

      if (typeof xp.Notes === "undefined" || xp.Notes === null) {
        xp.Notes = Array<string>();
      }

      xp.Status = "Picked";
      xp.Assignment = undefined;
      xp.Notes.push(
        `Order ${orderId} fully picked on ${moment().format(
          "MMMM Do YYYY, h:mm:ss a"
        )} by ${me?.FirstName} ${me?.LastName}`
      );

      await Orders.Patch("All", orderId ?? "", { xp: xp })
        .then(async (result) => {
          if (logging) {
            console.log(result);
          }
          if (typeof callback !== "undefined") {
            callback(result);
          } else {
            console.log("Invalid or no callback provided for PickedOrder");
          }
        })
        .catch((err) => {
          console.log(err);
          if (typeof failureCallback !== "undefined") {
            failureCallback();
          }
        })
        .finally(() => {});
    })
    .catch((err) => {
      console.log(err);
    });
};

export const CompleteOrder = async (
  orderId: string | null,
  me: MeUser<any> | null,
  callback: (order: Order<OrderXp, any, any>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Orders.Get("All", orderId ?? "")
    .then(async (result) => {
      let xp = result.xp;

      if (typeof xp.Notes === "undefined" || xp.Notes === null) {
        xp.Notes = Array<string>();
      }

      xp.Status = "Completed";
      xp.Assignment = undefined;
      xp.Notes.push(
        `Order ${orderId} completed on ${moment().format(
          "MMMM Do YYYY, h:mm:ss a"
        )} by ${me?.FirstName} ${me?.LastName}`
      );

      await Orders.Patch("All", orderId ?? "", { xp: xp })
        .then(async (result) => {
          if (logging) {
            console.log(result);
          }

          await Orders.Complete("All", orderId ?? "")
            .then((result) => {
              console.log(result);
              if (typeof callback !== "undefined") {
                callback(result);
              } else {
                console.log(
                  "Invalid or no callback provided for CompleteOrder"
                );
              }
            })
            .catch((err) => {
              console.log(err);
              if (typeof failureCallback !== "undefined") {
                failureCallback();
              }
            });
        })
        .catch((err) => {
          console.log(err);
          if (typeof failureCallback !== "undefined") {
            failureCallback();
          }
        })
        .finally(() => {});
    })
    .catch((err) => {
      console.log(err);
    });
};

export const MarkAsCompleteOrder = async (
  orderId: string | null,
  me: MeUser<any> | null,
  callback: (order: Order<OrderXp, any, any>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Orders.Get("All", orderId ?? "")
    .then(async (result) => {
      let xp = result.xp;

      if (typeof xp.Notes === "undefined" || xp.Notes === null) {
        xp.Notes = Array<string>();
      }

      xp.Status = "Completed";
      xp.Assignment = undefined;
      xp.Notes.push(
        `Order ${orderId} completed on ${moment().format(
          "MMMM Do YYYY, h:mm:ss a"
        )} by ${me?.FirstName} ${me?.LastName}`
      );

      await Orders.Patch("All", orderId ?? "", { xp: xp })
        .then(async (result) => {
          if (logging) {
            console.log(result);
          }

          if (typeof callback !== "undefined") {
            callback(result);
          } else {
            console.log(
              "Invalid or no callback provided for MarkAsCompleteOrder"
            );
          }
        })
        .catch((err) => {
          console.log(err);
          if (typeof failureCallback !== "undefined") {
            failureCallback();
          }
        })
        .finally(() => {});
    })
    .catch((err) => {
      console.log(err);
    });
};

export const CancelOrder = async (
  orderId: string | null,
  me: MeUser<any> | null,
  callback: (order: Order<OrderXp, any, any>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Orders.Get("All", orderId ?? "")
    .then(async (result) => {
      let xp = result.xp;

      if (typeof xp.Notes === "undefined" || xp.Notes === null) {
        xp.Notes = Array<string>();
      }

      xp.Status = "Cancelled";
      xp.Assignment = undefined;
      xp.Notes.push(
        `Order ${orderId} cancelled on ${moment().format(
          "MMMM Do YYYY, h:mm:ss a"
        )} by ${me?.FirstName} ${me?.LastName}`
      );

      await Orders.Patch("All", orderId ?? "", { xp: xp })
        .then(async (result) => {
          if (logging) {
            console.log(result);
          }

          await Orders.Cancel("All", orderId ?? "")
            .then((result) => {
              console.log(result);
              if (typeof callback !== "undefined") {
                callback(result);
              } else {
                console.log("Invalid or no callback provided for CancelOrder");
              }
            })
            .catch((err) => {
              console.log(err);
              if (typeof failureCallback !== "undefined") {
                failureCallback();
              }
            });
        })
        .catch((err) => {
          console.log(err);
          if (typeof failureCallback !== "undefined") {
            failureCallback();
          }
        })
        .finally(() => {});
    })
    .catch((err) => {
      console.log(err);
    });
};

export const UpdateDeliveryStatusWithCallback = async (
  orderId: string | null,
  delivery: DeliveryTypes,
  me: MeUser<any> | null,
  callback: (order: Order<OrderXp, any, any>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Orders.Get("All", orderId ?? "")
    .then(async (result: Order<OrderXp, any, any>) => {
      let xp = result.xp ?? {
        Assignment: { AssignedTo: "", FullName: "" },
        Status: "InProgress",
        FulfillmentType: "Delivery",
        Delivery: delivery,
      };

      if (xp.Status !== "Picked" || xp.FulfillmentType !== "Delivery") {
        failureCallback();
        return;
      }

      if (typeof xp.Notes === "undefined" || xp.Notes === null) {
        xp.Notes = Array<string>();
      }

      xp.Delivery = delivery;
      xp.Status = delivery === "Delivered" ? "Completed" : xp.Status;
      xp.Notes.push(
        `Order ${orderId} marked as ${
          delivery === "Delivered"
            ? "Delivered and completed"
            : "Out for Delivery"
        } on ${moment().format("MMMM Do YYYY, h:mm:ss a")} by ${
          me?.FirstName
        } ${me?.LastName}`
      );

      await Orders.Patch("All", orderId ?? "", { xp: xp })
        .then(async (result) => {
          if (logging) {
            console.log(result);
          }
          if (typeof callback !== "undefined") {
            callback(result);
          } else {
            console.log(
              "Invalid or no callback provided for UpdateDeliveryStatusWithCallback"
            );
          }
        })
        .catch((err) => {
          console.log(err);
          if (typeof failureCallback !== "undefined") {
            failureCallback();
          }
        })
        .finally(() => {});
    })
    .catch((err) => {
      console.log(err);
    });
};

export const HoldOrder = async (
  orderId: string | null,
  me: MeUser<any> | null,
  callback: (order: Order<OrderXp, any, any>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Orders.Get("All", orderId ?? "")
    .then(async (result: Order<OrderXp, any, any>) => {
      let xp = result.xp ?? {
        Assignment: { AssignedTo: "", FullName: "" },
        Status: "InProgress",
        FulfillmentType: "Delivery",
        Delivery: "OutForDelivery",
      };

      if (xp.Status !== "InProgress") {
        failureCallback();
        return;
      }

      if (typeof xp.Notes === "undefined" || xp.Notes === null) {
        xp.Notes = Array<string>();
      }

      xp.Status = "OnHold";
      xp.Assignment = undefined;
      xp.Notes.push(
        `Order ${orderId} put on-hold on ${moment().format(
          "MMMM Do YYYY, h:mm:ss a"
        )} by ${me?.FirstName} ${me?.LastName}`
      );

      await Orders.Patch("All", orderId ?? "", { xp: xp })
        .then(async (result) => {
          if (logging) {
            console.log(result);
          }
          if (typeof callback !== "undefined") {
            callback(result);
          } else {
            console.log("Invalid or no callback provided for HoldOrder");
          }
        })
        .catch((err) => {
          console.log(err);
          if (typeof failureCallback !== "undefined") {
            failureCallback();
          }
        })
        .finally(() => {});
    })
    .catch((err) => {
      console.log(err);
    });
};

export const UnHoldOrder = async (
  orderId: string | null,
  me: MeUser<any> | null,
  callback: (order: Order<OrderXp, any, any>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Orders.Get("All", orderId ?? "")
    .then(async (result: Order<OrderXp, any, any>) => {
      let xp = result.xp ?? {
        Assignment: { AssignedTo: "", FullName: "" },
        Status: "OnHold",
        FulfillmentType: "Delivery",
        Delivery: "OutForDelivery",
      };

      if (xp.Status !== "OnHold") {
        failureCallback();
        return;
      }

      if (typeof xp.Notes === "undefined" || xp.Notes === null) {
        xp.Notes = Array<string>();
      }

      xp.Status = "InProgress";
      xp.Assignment = undefined;
      xp.Notes.push(
        `Order ${orderId} put back In-Progress on ${moment().format(
          "MMMM Do YYYY, h:mm:ss a"
        )} by ${me?.FirstName} ${me?.LastName}`
      );

      await Orders.Patch("All", orderId ?? "", { xp: xp })
        .then(async (result) => {
          if (logging) {
            console.log(result);
          }
          if (typeof callback !== "undefined") {
            callback(result);
          } else {
            console.log("Invalid or no callback provided for UnHoldOrder");
          }
        })
        .catch((err) => {
          console.log(err);
          if (typeof failureCallback !== "undefined") {
            failureCallback();
          }
        })
        .finally(() => {});
    })
    .catch((err) => {
      console.log(err);
    });
};

export const DeleteLineItemWithCallback = async (
  orderId: string | null,
  lineItemId: string,
  callback: () => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await LineItems.Delete("All", orderId ?? "", lineItemId)
    .then(async (result) => {
      if (logging) {
        console.log(result);
      }
      if (typeof callback !== "undefined") {
        callback();
      } else {
        console.log("Invalid or no callback provided for DeleteLineItem");
      }
    })
    .catch((err) => {
      console.log(err);
      if (typeof failureCallback !== "undefined") {
        failureCallback();
      }
    })
    .finally(() => {});
};

export const UpdateLineItemQtyWithCallback = async (
  orderId: string | null,
  lineItemId: string,
  qty: number,
  callback: (lineItem: LineItem<LineItemXp, any, VariantXp>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await LineItems.Patch("All", orderId ?? "", lineItemId, { Quantity: qty })
    .then(async (lineItem) => {
      if (logging) {
        console.log(lineItem);
      }
      if (typeof callback !== "undefined") {
        callback(lineItem);
      } else {
        console.log(
          "Invalid or no callback provided for UpdateLineItemQtyWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
      if (typeof failureCallback !== "undefined") {
        failureCallback();
      }
    })
    .finally(() => {});
};

export const UpdateLineItemWithCallback = async (
  orderId: string | null,
  productId: string | null,
  lineItemId: string,
  qty: number,
  specs: Array<{ specId: string; optionId: string }>,
  callback: (lineItem: LineItem<LineItemXp, any, VariantXp>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  const lineItemSpecs = specs.map((s) => {
    return { SpecID: s.specId, OptionID: s.optionId };
  });
  await LineItems.Patch("All", orderId ?? "", lineItemId, {
    Quantity: qty,
    ProductID: productId ?? "",
    Specs: lineItemSpecs,
  })
    .then(async (lineItem) => {
      if (logging) {
        console.log(lineItem);
      }
      if (typeof callback !== "undefined") {
        callback(lineItem);
      } else {
        console.log(
          "Invalid or no callback provided for UpdateLineItemQtyWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
      if (typeof failureCallback !== "undefined") {
        failureCallback();
      }
    })
    .finally(() => {});
};

export const AddLineItemWithCallback = async (
  orderId: string | null,
  productId: string,
  qty: number,
  specs: Array<{ specId: string; optionId: string }>,
  callback: (lineItem: LineItem<LineItemXp, any, VariantXp>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  const lineItemSpecs = specs.map((s) => {
    return { SpecID: s.specId, OptionID: s.optionId };
  });

  await LineItems.Create("All", orderId ?? "", {
    ProductID: productId,
    Quantity: qty,
    Specs: lineItemSpecs,
    xp: {
      Picked: false,
    },
  })
    .then(async (lineItem) => {
      if (logging) {
        console.log(lineItem);
      }
      if (typeof callback !== "undefined") {
        callback(lineItem);
      } else {
        console.log(
          "Invalid or no callback provided for AddLineItemWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
      if (typeof failureCallback !== "undefined") {
        failureCallback();
      }
    })
    .finally(() => {});
};

export const AddPaymentWithCallback = async (
  orderId: string | null,
  callback: (lineItem: Payment<PaymentXp>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Payments.Create("All", orderId ?? "", {
    Type: "PurchaseOrder",
    Accepted: true,
    xp: {
      PO: "12345",
    },
  })
    .then(async (payment) => {
      if (logging) {
        console.log(payment);
      }
      if (typeof callback !== "undefined") {
        callback(payment);
      } else {
        console.log(
          "Invalid or no callback provided for AddPaymentWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
      if (typeof failureCallback !== "undefined") {
        failureCallback();
      }
    })
    .finally(() => {});
};

export const SubmitOrderWithCallback = async (
  orderId: string | null,
  callback: (order: Order<OrderXp, any, any>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Orders.Submit("All", orderId ?? "")
    .then(async (order) => {
      if (logging) {
        console.log(order);
      }
      if (typeof callback !== "undefined") {
        callback(order);
      } else {
        console.log(
          "Invalid or no callback provided for SubmitOrderWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
      if (typeof failureCallback !== "undefined") {
        failureCallback();
      }
    })
    .finally(() => {});
};

export const CreateShipmentWithCallback = async (
  orderId: string | null,
  callback: (order: Order<OrderXp, any, any>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Orders.Ship("All", orderId ?? "", {
    DateDelivered: moment().toISOString(),
  })
    .then(async (order) => {
      if (logging) {
        console.log(order);
      }
      if (typeof callback !== "undefined") {
        callback(order);
      } else {
        console.log(
          "Invalid or no callback provided for CreateShipmentWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
      if (typeof failureCallback !== "undefined") {
        failureCallback();
      }
    })
    .finally(() => {});
};

export const CreateOrderWithCallback = async (
  orderId: string,
  fromCompany: string,
  toCompany: string,
  fulfillment: FulfillmentTypes,
  callback: (order: Order<OrderXp, any, any>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Orders.Create("All", {
    ID: orderId,
    FromCompanyID: fromCompany,
    ToCompanyID: toCompany,
    xp: { FulfillmentType: fulfillment },
  })
    .then(async (order) => {
      if (logging) {
        console.log(order);
      }
      if (typeof callback !== "undefined") {
        callback(order);
      } else {
        console.log(
          "Invalid or no callback provided for CreateOrderWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
      if (typeof failureCallback !== "undefined") {
        failureCallback();
      }
    })
    .finally(() => {});
};

export const CreateBillingAddressWithCallback = async (
  orderId: string,
  firstName: string,
  lastName: string,
  phone: string,
  street: string,
  city: string,
  state: string,
  zip: string,
  country: string,
  callback: (order: Order<OrderXp, any, any>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Orders.SetBillingAddress("All", orderId, {
    City: city,
    Country: country,
    State: state,
    Street1: street,
    Zip: zip,
    AddressName: "Billing Address",
    FirstName: firstName,
    LastName: lastName,
    Phone: phone,
  })
    .then(async (order) => {
      if (logging) {
        console.log(order);
      }
      if (typeof callback !== "undefined") {
        callback(order);
      } else {
        console.log(
          "Invalid or no callback provided for CreateOrderWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
      if (typeof failureCallback !== "undefined") {
        failureCallback();
      }
    })
    .finally(() => {});
};
