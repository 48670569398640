import { Supplier, Suppliers } from "ordercloud-javascript-sdk";

export const GetSuppliersWithCallback = async (
  callback: (suppliers: Array<Supplier<any>>) => void,
  store?: string,
  logging: boolean = false
) => {
  await Suppliers.List(
    typeof store !== "undefined" ? { search: store, searchOn: ["ID"] } : {}
  )
    .then((suppliers) => {
      if (logging) {
        console.log(suppliers);
      }
      if (typeof callback !== "undefined") {
        callback(suppliers.Items);
      } else {
        console.log(
          "Invalid or no callback provided for GetSuppliersWithCallback"
        );
      }
    })
    .catch((err) => console.log(err));
};

export const GetSupplierWithCallback = async (
  callback: (suppliers: Supplier<any>) => void,
  failureCallback: () => void,
  store?: string,
  logging: boolean = false
) => {
  await Suppliers.Get(store ?? "")
    .then((supplier) => {
      if (supplier === null) {
        if (typeof failureCallback !== "undefined") {
          failureCallback();
        }
      }
      if (logging) {
        console.log(supplier);
      }
      if (typeof callback !== "undefined") {
        callback(supplier);
      } else {
        console.log(
          "Invalid or no callback provided for GetSuppliersWithCallback"
        );
      }
    })
    .catch((err) => {
      if (typeof failureCallback !== "undefined") {
        failureCallback();
      }
      console.log(err);
    });
};
