/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MeUser, Order, Shipment, Supplier } from "ordercloud-javascript-sdk";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "../../../../footer";
import Header from "../../../../header";
import Icon from "react-icons-kit";
import { search as searchIcon } from "react-icons-kit/feather/search";
import { refreshCw as refresh } from "react-icons-kit/feather/refreshCw";

import { OrderXp, ShippingXp } from "../../../../../types/OrderCloud/xp";
import { OrderToShipmentsMap } from "../../../../../types/OrderCloud/mappings";
import { TabType } from "../../../../../types/tabButtonProps";
import TabButton from "../../../../shared/tabButton";
import {
  GetMyOrdersWithCallback,
  GetNotStartedOrdersWithCallback,
  GetOrderByIDAndTabWithCallback,
  GetOrderShipmentsWithCallback,
} from "../../../../../lib/orderHelper";
import { GetSuppliersWithCallback } from "../../../../../lib/supplierHelper";
import { GetMeWithCallback } from "../../../../../lib/meHelper";
import Title from "../../../../shared/title";
import OrderComponent from "../../../../shared/orderComponent";

export default function PrepareOrders(): JSX.Element {
  const [notStarted, setNotStared] = useState<number>(0);
  const [myOrders, setMyOrders] = useState<number>(0);
  const [selected, setSelected] = useState<boolean>(false);
  const [tab, setTab] = useState<TabType>("All");
  const [lockedOrders, setLockedOrders] = useState<Array<string>>(
    Array<string>()
  );
  const [me, setMe] = useState<MeUser<any> | null>(null);
  const [supplier, setSupplier] = useState<Supplier<any> | null>(null);
  const [shipments, setShipments] = useState<Array<OrderToShipmentsMap> | null>(
    null
  );
  const [search, setSearch] = useState("");
  const params = useParams();
  const navigate = useNavigate();

  const [orders, setOrders] = useState<Array<Order<OrderXp, any, any>> | null>(
    null
  );

  const handleSearch = () => {
    setOrders(null);
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    const getData = async (id: string): Promise<void> => {
      await GetOrderByIDAndTabWithCallback(
        id,
        tab,
        selected,
        search,
        me,
        callback
      );
    };

    const getNotStarted = async () => {
      await GetNotStartedOrdersWithCallback(
        supplier?.ID ?? "",
        (orders: Array<Order<any, any, OrderXp>>) => {
          setNotStared(orders.length);
        }
      );
    };

    const getMine = async () => {
      await GetMyOrdersWithCallback(
        supplier?.ID ?? "",
        me,
        (orders: Array<Order<any, any, OrderXp>>) => {
          setMyOrders(orders.length);
        }
      );
    };

    const getShipping = async (order: Order<OrderXp, any, any>) => {
      await GetOrderShipmentsWithCallback(order.ID ?? "", shipmentsCallback);
    };

    const getSuppliers = async (): Promise<void> => {
      await GetSuppliersWithCallback(suppliersCallback, params.store);
    };

    const getMe = async (): Promise<void> => {
      await GetMeWithCallback(meCallback);
    };

    const suppliersCallback = (suppliers: Array<Supplier<any>>) => {
      if (suppliers.length > 0) {
        setSupplier(suppliers[0]);
        getData(suppliers[0].ID ?? "");
      } else {
        navigate("/notfound");
      }
    };

    const meCallback = (me: MeUser<any>) => {
      setMe(me);
    };

    const callback = (orders: Array<Order<any, any, OrderXp>>) => {
      setShipments(null);
      orders.forEach(async (o) => {
        await getShipping(o);
      });

      setOrders(orders);
    };

    const shipmentsCallback = (
      orderId: string,
      shipmentItems: Array<Shipment<ShippingXp, any, any>>
    ) => {
      const shipmentsArray = shipments ?? Array<OrderToShipmentsMap>();
      shipmentsArray.push({ OrderId: orderId ?? "", Shipments: shipmentItems });
      setShipments(shipments);
    };

    if (typeof me === "undefined" || me === null) {
      getMe();
    }
    if (typeof supplier === "undefined" || supplier === null) {
      getSuppliers();
    } else {
      if (typeof orders === "undefined" || orders === null) {
        getData(supplier.ID ?? "");
        if (tab === "All") {
          getNotStarted();
        }
        if (tab === "Assigned") {
          getMine();
        }
      }
    }
  }, [supplier, orders, notStarted]);

  const onTabClick = (tab: TabType) => {
    setTab(tab);
    setOrders(null);
  };

  const onSelectedChange = () => {
    const sel = !selected;
    setSelected(sel);
    setOrders(null);
  };

  const onContinueOrder = (id:string) => {
      navigate(`/store/${supplier?.ID}/${id}`);
  };

  if (supplier === null) return <></>;
  return (
    <div className="flex min-h-screen flex-col">
      <Header title="Prepare Orders" backLink={`/store/${supplier.ID}`} />
      <div className="ml-2 min-h-fit mb-4 mr-2">
        <Title title={supplier.Name} />
        {typeof notStarted !== "undefined" &&
          notStarted > 0 &&
          tab === "All" && (
            <>
              <span className="sr-only">Not Started</span>
              <div className="absolute inline-flex items-center justify-center cursor-help w-2 h-2 text-xs font-bold text-white bg-red border-2 border-white rounded-full top-notification left-7 dark:border-grey-400" title={`${notStarted} Unassigned Orders`}>
                {notStarted}
              </div>
            </>
          )}

        {typeof myOrders !== "undefined" &&
          myOrders > 0 &&
          tab === "Assigned" && (
            <>
              <span className="sr-only">My Orders</span>
              <div className="absolute inline-flex items-center justify-center cursor-help w-2 h-2 text-xs font-bold text-white bg-blue-100 border-2 border-white rounded-full top-notification left-mine dark:border-grey-400" title={`${myOrders} Assigned to me`}>
                {myOrders}
              </div>
            </>
          )}
        <div className="grid grid-cols-12 w-[400px]">
          <TabButton
            currentTabType={tab}
            tabType={"All"}
            onTabClick={onTabClick}
            count={notStarted}
          />
          <TabButton
            currentTabType={tab}
            tabType={"Assigned"}
            onTabClick={onTabClick}
          />
          <TabButton
            currentTabType={tab}
            tabType={"InProgress"}
            onTabClick={onTabClick}
          />
          <TabButton
            currentTabType={tab}
            tabType={"OnHold"}
            onTabClick={onTabClick}
          />
          <TabButton
            currentTabType={tab}
            tabType={"Completed"}
            onTabClick={onTabClick}
          />
          <div className="col-span-2"></div>
        </div>
        <div className="flex">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-400 leading-tight focus:outline-none focus:shadow-outline max-h-2 mt-1"
            id="search"
            type="text"
            placeholder="Search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            onKeyDown={handleKeyDown}
          />
          <span
            className="flex justify-around items-center"
            onClick={handleSearch}
          >
            <Icon
              className="absolute mr-5 mt-1 cursor-pointer"
              icon={searchIcon}
              size={14}
            />
          </span>
        </div>
        <div className="mt-2 ">
          <span>
            <input
              type="checkbox"
              id="selected"
              name="selected"
              value={selected ? "selected" : ""}
              onChange={() => onSelectedChange()}
            />
            <label htmlFor="selected"> My Orders</label>
          </span>
          <span
            className="float-right cursor-pointer"
            onClick={() => {
              setOrders(null);
            }}
          >
            <Icon icon={refresh} size={14} /> Refresh
          </span>
        </div>
        <div className="mt-2">
          <>
            {orders !== null && orders.length === 0 && (
              <>
                <img src={"/assets/img/empty.gif"}></img>
                <div className="w-full text-center text-lg">
                  It’s looking a little empty in here!
                </div>
              </>
            )}
            {orders !== null &&
              orders?.map((o, i) => {
                const shipmentsArray =
                  shipments?.find((s) => {
                    return s.OrderId === o.ID;
                  })?.Shipments ?? null;
                const shipment =
                  shipmentsArray !== null && shipmentsArray?.length > 0
                    ? shipmentsArray[0]
                    : null;
                return (
                  <OrderComponent
                    currentOrder={o}
                    lockedOrders={lockedOrders}
                    me={me}
                    orders={orders}
                    setLockedOrders={setLockedOrders}
                    setOrders={setOrders}
                    shipment={shipment}
                    key={i}
                    onContinueOrder={onContinueOrder}
                  ></OrderComponent>
                );
              })}
          </>
        </div>
      </div>
      <Footer selected="Home" />
    </div>
  );
}
