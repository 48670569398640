import { BrowserRouter } from "react-router-dom";
import Main from "./components/routes";
import { Configuration } from "ordercloud-javascript-sdk";
import AppLogOut from "./components/appLogOut";
function App() {
  document.body.className = "flex h-full flex-col";

  Configuration.Set({
    baseApiUrl: "https://sandboxapi.ordercloud.io",
    timeoutInMilliseconds: 20 * 1000,
  });

  return (
    <div>
      <AppLogOut>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </AppLogOut>
    </div>
  );
}

export default App;
