/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  LineItem,
  LineItems,
  MeUser,
  Order,
  Shipment,
  Supplier,
} from "ordercloud-javascript-sdk";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "../../../../../footer";
import Header from "../../../../../header";
import {
  LineItemXp,
  OrderXp,
  ShippingXp,
  VariantXp,
} from "../../../../../../types/OrderCloud/xp";
import {
  OrderToLineItemsMap,
  OrderToShipmentsMap,
} from "../../../../../../types/OrderCloud/mappings";
import Icon from "react-icons-kit";
import { minus } from "react-icons-kit/feather/minus";
import { rotateCcw } from "react-icons-kit/feather/rotateCcw";
import SwipeToRevealActions from "react-swipe-to-reveal-actions";
import {
  CompleteOrder,
  GetOrderByIdWithCallback,
  GetOrderLineItemsWithCallback,
  GetOrderShipmentsWithCallback,
  PickedOrder,
} from "../../../../../../lib/orderHelper";
import { GetSupplierWithCallback } from "../../../../../../lib/supplierHelper";
import { GetMeWithCallback } from "../../../../../../lib/meHelper";
import BottomButton from "../../../../../shared/bottomButton";
import Title from "../../../../../shared/title";
import Notes from "../../../../../shared/notes";
import ClientComponent from "../../../../../shared/clientComponent";
import AssignedTo from "../../../../../shared/assignedTo";
import CompletedProgress from "../../../../../shared/completedProgress";
import { confirmAlert } from "react-confirm-alert";
import LineItemComponent from "../../../../../shared/lineItemComponent";
import TabButton from "../../../../../shared/tabButton";
import { TabType } from "../../../../../../types/tabButtonProps";

export default function PickList(): JSX.Element {
  const [tab, setTab] = useState<TabType>("Short");
  const [me, setMe] = useState<MeUser<any> | null>(null);
  const [supplier, setSupplier] = useState<Supplier<any> | null>(null);
  const [shipments, setShipments] = useState<Array<OrderToShipmentsMap> | null>(
    null
  );
  const [lineItems, setLineItems] = useState<Array<OrderToLineItemsMap> | null>(
    null
  );

  const [lockedLineItems, setLockedLineItems] = useState<Array<string>>(
    Array<string>()
  );

  const params = useParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState<Order<OrderXp, any, any> | null>(null);

  const buttonComponent = (
    li: LineItem<LineItemXp, any, VariantXp>,
    order: Order<OrderXp, any, any> | null
  ) => {
    if (
      typeof li.Variant === "undefined" ||
      typeof li.Variant?.xp === "undefined" ||
      li.Variant?.xp === null ||
      order?.xp?.Status !== "InProgress"
    )
      return <></>;
    if (
      (li.xp?.Picked ?? false) === false &&
      order?.xp?.Assignment?.AssignedTo === me?.ID
    ) {
      if (lockedLineItems.indexOf(li?.ID ?? "") > -1) {
        return (
          <button
            className={` text-black font-bold py-1 px-4 rounded-lg bg-grey-300 hover:bg-grey-300 border-black cursor-not-allowed border-1 w-full mt-1 col-span-12`}
            type="button"
          >
            <span>
              <svg
                aria-hidden="true"
                className="w-1 h-1 mr-2 ml-1 text-white animate-spin dark:text-white fill-orange text-center"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </span>
          </button>
        );
      } else {
        return (
          <button
            className={` text-black font-bold py-1 px-4 rounded-lg bg-white hover:bg-white border-black cursor-pointer border-1 w-full mt-1 col-span-12`}
            type="button"
            onClick={async () => {
              await saveLineItemPicked(li);
            }}
          >
            <div className="w-full h-2">Mark line item as Picked</div>
          </button>
        );
      }
    }

    return <></>;
  };

  useEffect(() => {
    const getData = async (): Promise<void> => {
      await GetOrderByIdWithCallback(params.order, callback, failureCallback);
    };

    const getSupplier = async (): Promise<void> => {
      await GetSupplierWithCallback(
        supplierCallback,
        supplierFailureCallback,
        params.store
      );
    };

    const getMe = async (): Promise<void> => {
      await GetMeWithCallback(meCallback);
    };

    const getShipping = async (order: Order<OrderXp, any, any>) => {
      await GetOrderShipmentsWithCallback(order.ID ?? "", shipmentsCallback);
    };

    const getLineItems = async (order: Order<OrderXp, any, any>) => {
      await GetOrderLineItemsWithCallback(order.ID ?? "", lineItemsCallback);
    };
    const failureCallback = () => {
      confirmAlert({
        title: "Unable to find the order.",
        buttons: [
          {
            label: "Ok",
            onClick: async () =>
              navigate(`/store/${params.store}/prepare-orders`),
          },
        ],
      });
    };

    const supplierFailureCallback = () => {
      confirmAlert({
        title: "Unable to find the supplier.",
        buttons: [
          {
            label: "Ok",
            onClick: async () =>
              navigate(`/store/${params.store}/prepare-orders`),
          },
        ],
      });
    };

    const lineItemsCallback = (
      orderId: string,
      orderLineItems: Array<LineItem<LineItemXp, any, VariantXp>>
    ) => {
      const lineItemsArray = lineItems ?? Array<OrderToLineItemsMap>();
      lineItemsArray.push({
        OrderId: orderId ?? "",
        LineItems: orderLineItems,
      });

      setLineItems(lineItemsArray);
    };

    const shipmentsCallback = (
      orderId: string,
      shipmentItems: Array<Shipment<ShippingXp, any, any>>
    ) => {
      const shipmentsArray = shipments ?? Array<OrderToShipmentsMap>();
      shipmentsArray.push({ OrderId: orderId ?? "", Shipments: shipmentItems });
      setShipments(shipments);
    };

    const meCallback = (me: MeUser<any>) => {
      setMe(me);
    };

    const callback = async (order: Order<OrderXp, any, any>) => {
      setShipments(null);
      setLineItems(null);

      await getShipping(order);
      await getLineItems(order);

      setOrder(order);
    };

    const supplierCallback = (supplier: Supplier<any>) => {
      if (supplier !== null) {
        setSupplier(supplier);
      } else {
        supplierFailureCallback();
      }
    };

    if (typeof me === "undefined" || me === null) {
      getMe();
    }
    if (typeof supplier === "undefined" || supplier === null) {
      getSupplier();
    }
    if (typeof order === "undefined" || order === null) {
      getData();
    }
  }, [supplier, order, lineItems]);

  if (supplier === null) return <></>;

  const maxQuantity =
    lineItems !== null
      ? lineItems[0].LineItems.reduce((a, v) => (a = a + (v?.Quantity ?? 0)), 0)
      : 0;

  const pickedQuantity =
    lineItems !== null
      ? lineItems[0].LineItems.filter((li) => {
          return li.xp?.Picked === true;
        }).reduce((a, v) => (a = a + (v?.Quantity ?? 0)), 0)
      : 0;

  const saveOrderCompleted = async () => {
    await CompleteOrder(
      order?.ID ?? null,
      me,
      (order: Order<OrderXp, any, any>) => {
        setOrder(order);
        navigate(`/store/${supplier.ID ?? null}/prepare-orders`);
      },
      () => {
        confirmAlert({
          title: "Failed to complete the order",
          buttons: [
            {
              label: "Ok",
              onClick: async () => {
                navigate(`/store/${supplier.ID ?? null}/prepare-orders`);
              },
            },
          ],
        });
      }
    );
  };

  const saveLineItemPicked = async (
    lineItem: LineItem<LineItemXp, any, VariantXp>
  ) => {
    if (typeof order === "undefined" || order === null) {
      return;
    }

    let xp = lineItem?.xp ?? { Picked: false };

    xp.Picked = true;

    lineItem.xp = xp;

    if (
      lockedLineItems.indexOf(lineItem.ID ?? "") === -1 &&
      typeof lineItem.ID !== "undefined"
    ) {
      const locked = lockedLineItems;
      locked.push(lineItem.ID);
      setLockedLineItems(locked);
    }

    await LineItems.Save("All", order?.ID ?? "", lineItem?.ID ?? "", lineItem)
      .then(async (result) => {
        const newlineItems =
          lineItems
            ?.find((li) => {
              return li.OrderId === order.ID;
            })
            ?.LineItems.map((li) => {
              return li.ID === lineItem.ID ? result : li;
            }) ?? Array<LineItem<LineItemXp, any, VariantXp>>();

        const mappedLineItem: OrderToLineItemsMap = {
          OrderId: order?.ID ?? "",
          LineItems: newlineItems,
        };

        const newMappedLineItems =
          lineItems?.map((li) => {
            return li.OrderId === order.ID ? mappedLineItem : li;
          }) ?? Array<OrderToLineItemsMap>();

        setLineItems(newMappedLineItems);

        const maxQuantity =
          newMappedLineItems !== null
            ? newMappedLineItems[0].LineItems.reduce(
                (a, v) => (a = a + (v?.Quantity ?? 0)),
                0
              )
            : 0;

        const pickedQuantity =
          newMappedLineItems !== null
            ? newMappedLineItems[0].LineItems.filter((li) => {
                return li.xp?.Picked === true;
              }).reduce((a, v) => (a = a + (v?.Quantity ?? 0)), 0)
            : 0;

        if (pickedQuantity === maxQuantity) {
          await PickedOrder(
            order?.ID ?? "",
            me,
            (order: Order<OrderXp, any, any>) => {},
            () => {}
          );
        }

        const locked = lockedLineItems.filter((lo) => {
          return lo !== lineItem.ID;
        });

        setLockedLineItems(locked);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const onTabClick = (tab: TabType) => {
    setTab(tab);
  };

  return (
    <div className="flex min-h-screen flex-col">
      <Header
        title="Pick List"
        backLink={`/store/${supplier.ID}/prepare-orders`}
      />
      <CompletedProgress
        maxQuantity={maxQuantity}
        pickedQuantity={pickedQuantity}
      />
      <AssignedTo fullName={order?.xp?.Assignment?.FullName ?? ""} />
      <div className="ml-2 min-h-fit mb-4 mr-2 ">
        <Title title={supplier.Name} subTitle={`Order: ${order?.ID}`} />

        {typeof order?.xp?.Assignment === "undefined" ||
        order?.xp?.Assignment === null ||
        typeof order?.xp?.Assignment?.AssignedTo === "undefined" ||
        order?.xp?.Assignment?.AssignedTo === null ||
        order?.xp?.Assignment?.AssignedTo === "" ? (
          <></>
        ) : (
          <>
            <div className="grid grid-cols-12 mb-1">
              <TabButton
                currentTabType={tab}
                tabType={"Short"}
                onTabClick={onTabClick}
                tabText="Latest"
              />

              <TabButton
                currentTabType={tab}
                tabType={"Full"}
                onTabClick={onTabClick}
                tabText="Full History"
              />
            </div>
            <Notes
              notes={order?.xp?.Notes ?? Array<string>()}
              orderId={order?.ID ?? ""}
              take={tab === "Short" ? 3 : undefined}
              setOrder={setOrder}
            />
          </>
        )}

        <ClientComponent
          firstName={
            order?.BillingAddress?.FirstName ?? order?.FromUser?.FirstName ?? ""
          }
          lastName={
            order?.BillingAddress?.LastName ?? order?.FromUser?.LastName ?? ""
          }
          phone={order?.BillingAddress?.Phone ?? order?.FromUser?.Phone ?? ""}
        />

        {lineItems !== null &&
          lineItems[0].LineItems.map((li, i) => {
            return (
              <SwipeToRevealActions
                key={i}
                hideDotsButton={true}
                actionButtons={
                  li.xp?.Picked ||
                  typeof order?.xp?.Assignment === "undefined" ||
                  order?.xp?.Assignment === null ||
                  typeof order?.xp?.Assignment?.AssignedTo === "undefined" ||
                  order?.xp?.Assignment?.AssignedTo === null ||
                  order?.xp?.Assignment?.AssignedTo === ""
                    ? []
                    : [
                        {
                          content: (
                            <div
                              className="table overflow-hidden w-full ml-1 pr-1"
                              style={{ height: "140px" }}
                            >
                              <div className="text-white font-bold rounded-sm bg-blue-100 hover:bg-blue-100 focus:outline-none focus:shadow-black table-cell align-middle">
                                <span className="flex justify-around items-center">
                                  <Icon
                                    className=""
                                    icon={minus}
                                    size={12}
                                  ></Icon>
                                </span>
                                <span>Short</span>
                              </div>
                            </div>
                          ),
                          onClick: async () => {
                            navigate(
                              `/store/${params.store}/${params.order}/${li.ID}`
                            );
                          },
                        },

                        {
                          content: (
                            <div
                              className="table overflow-hidden w-full ml-1 pr-1"
                              style={{ height: "140px" }}
                            >
                              <div className="text-white font-bold rounded-sm bg-blue-200 hover:bg-blue-200 focus:outline-none focus:shadow-black table-cell align-middle">
                                <span className="flex justify-around items-center">
                                  <Icon
                                    className=""
                                    icon={rotateCcw}
                                    size={12}
                                  ></Icon>
                                </span>
                                <span>Substitute</span>
                              </div>
                            </div>
                          ),
                          onClick: async () => {
                            navigate(
                              `/store/${params.store}/${params.order}/${li.ID}/substitute-line-item`
                            );
                          },
                        },
                      ]
                }
                actionButtonMinWidth={150}
                height="240px"
              >
                <LineItemComponent
                  listItem={li}
                  button={buttonComponent(li, order)}
                  quantity={
                    <div className="col-span-2">
                      {li.xp?.Picked ?? false ? li.Quantity : 0}/{li.Quantity}
                    </div>
                  }
                />
              </SwipeToRevealActions>
            );
          })}
      </div>
      {pickedQuantity === maxQuantity &&
      order?.xp?.FulfillmentType !== "Delivery" &&
      order?.xp?.Assignment?.AssignedTo === me?.ID ? (
        <>
          {order?.Status === "Open" && (
            <BottomButton
              buttonText="Complete Order"
              onClickCallback={async () => await saveOrderCompleted()}
            ></BottomButton>
          )}
        </>
      ) : (
        <BottomButton
          buttonText="Print Pick List"
          onClickCallback={async () => {}}
        ></BottomButton>
      )}
      <Footer selected="Home" />
    </div>
  );
}
