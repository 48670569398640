/* eslint-disable jsx-a11y/anchor-is-valid */
import { LoginProps } from "../../types/loginProps";
import { useForm } from "react-hook-form";
import { useRef, useState } from "react";
import { Auth, Tokens } from "ordercloud-javascript-sdk";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import LoginHeader from "../loginHeader";
import PoweredBy from "../shared/poweredBy";

export default function Login(props: LoginProps): JSX.Element {
  const [processingLogin, setProcessingLogin] = useState<boolean>(false);
  const loginBtnRef = useRef<HTMLButtonElement>(null);

  const {
    register,
    setError,
    handleSubmit,
    clearErrors,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ mode: "onBlur", reValidateMode: "onChange" });

  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const [queryParameters] = useState(
    new URLSearchParams(window.location.search)
  );

  async function onLoginClick(data: any) {
    setProcessingLogin(true);

    try {
      Auth.ElevatedLogin(
        "OIxyObtJm7BJGGnHGdprul1MgBdIbyJhfF9lZfjHjyN69CX3ddkzkDAaHFZH",
        data.username,
        data.password,
        "CCE2141A-FA6A-4BF2-967D-9173E129E4E8"
      )
        .then((response) => {
          const token = response.access_token;
          Tokens.SetAccessToken(token);
          if (
            typeof queryParameters !== "undefined" &&
            typeof queryParameters.get("redirect") !== "undefined" &&
            queryParameters.get("redirect") !== null
          ) {
            window.location.pathname = `/${queryParameters.get("redirect")}`;
          } else {
            window.location.pathname = "/";
          }
        })
        .catch((err) => {
          setError("login", { type: "custom", message: "Login Failed" });
          setProcessingLogin(false);
        });
    } catch (error: any) {
      let message;
      if (error instanceof Error) {
        message = error.message;
      } else {
        message = String(error);
      }
      setError("login", { type: "custom", message: message });
      if (process.env.REACT_APP_SHOW_LOGGING) {
        console.error(message);
      }
      setProcessingLogin(false);
    } finally {
    }
  }

  const handleChange = (e: any) => {
    e.persist();
    setValue(e.target.name, e.target.value);
    trigger(e.target.name);
  };

  const buttonText = processingLogin ? (
    <div className="w-full flex justify-center">
      <svg
        aria-hidden="true"
        className="w-2 h-2 mr-2 ml-1 text-white animate-spin dark:text-white fill-orange text-center"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span className="sr-only">Please wait</span>
    </div>
  ) : (
    <>Sign In</>
  );

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  return (
    <div className="flex min-h-full flex-col">
      <LoginHeader title="Alpha Solutions </br> Order Fulfillment"></LoginHeader>
      <div className="py-4">
        <form
          className="bg-white px-3 pt-1 mb-2"
          onSubmit={handleSubmit(onLoginClick, () => {
            setProcessingLogin(false);
            clearErrors("login");
          })}
        >
          <div>
            <div className="mb-1">
              <label
                className="block text-grey-400 text-sm font-bold mb-0"
                htmlFor="username"
              >
                Email
              </label>
              <input
                className="appearance-none border-b  w-full py-1 px-0 text-grey-400  mb-1 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="text"
                placeholder="Add your email"
                {...register("username", {
                  required: true,
                  pattern:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                })}
                onBlur={handleChange}
              />
              {errors.username && errors.username.type === "required" && (
                <p className="text-red text-xs italic mb-1">
                  Please enter an email.
                </p>
              )}
              {errors.username && errors.username.type === "pattern" && (
                <p className="text-red text-xs italic mb-1">
                  Please enter a valid email.
                </p>
              )}
            </div>
            <div className="mb-1">
              <label
                className="block text-grey-400 text-sm font-bold mb-0"
                htmlFor="password"
              >
                Password
              </label>
              <div className="mb-1 flex">
                <input
                  className="appearance-none border-b  w-full py-1 px-o text-grey-400 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type={type}
                  placeholder="Add you password"
                  {...register("password", {
                    required: true,
                  })}
                  onBlur={handleChange}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setValue("password", e.target.value);
                  }}
                  autoComplete="current-password"
                  value={password}
                />
                <span
                  className="flex justify-around items-center"
                  onClick={handleToggle}
                >
                  <Icon className="absolute mr-1 mb-1" icon={icon} size={14} />
                </span>
              </div>
              {errors.password && errors.password.type === "required" && (
                <p className="text-red text-xs italic mb-1">
                  Please enter a password.
                </p>
              )}
            </div>
            <div className="mb-1">
              {errors.login && (
                <p className="text-red text-xs italic">
                  {errors.login.message?.toString()}
                </p>
              )}
            </div>
            <div className="flex items-center justify-between">
              <button
                className={` text-white font-bold py-1 w-full rounded   ${
                  processingLogin
                    ? "bg-grey-100 hover:bg-grey-100 cursor-not-allowed"
                    : "bg-grey-400 hover:bg-grey-400 focus:outline-none focus:shadow-black"
                }`}
                type="submit"
                disabled={processingLogin}
                ref={loginBtnRef}
                onClick={() => {
                  handleSubmit(onLoginClick, () => setProcessingLogin(false));
                }}
              >
                <div className="w-full h-2">{buttonText}</div>
              </button>
            </div>
            <div className="mt-3 text-center w-full">
              Don't have an account?{" "}
              <a
                href="#"
                className="font-medium text-blue-100 dark:text-blue-100 hover:underline"
              >
                Sign up
              </a>
            </div>
            <PoweredBy />
          </div>
        </form>
      </div>
    </div>
  );
}
