/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  LineItem,
  MeUser,
  Order,
  Product,
  Spec,
  Supplier,
} from "ordercloud-javascript-sdk";
import { useEffect, useState } from "react";
import {
  LineItemXp,
  OrderXp,
  VariantXp,
} from "../../../../../../../types/OrderCloud/xp";
import { GetMeWithCallback } from "../../../../../../../lib/meHelper";
import {
  AddLineItemWithCallback,
  DeleteLineItemWithCallback,
  GetOrderByIdWithCallback,
  GetOrderLineItemWithCallback,
  SaveNoteToOrderXp,
} from "../../../../../../../lib/orderHelper";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../../../header";
import { confirmAlert } from "react-confirm-alert";
import Title from "../../../../../../shared/title";
import { GetSupplierWithCallback } from "../../../../../../../lib/supplierHelper";
import LineItemComponent from "../../../../../../shared/lineItemComponent";
import {
  GetProductByIdWithCallback,
  GetProductSpecs,
  GetProductsBySearchWithCallback,
} from "../../../../../../../lib/productHelper";
import Footer from "../../../../../../footer";
import BottomButton from "../../../../../../shared/bottomButton";
import SelectLineItem from "../../../../../../shared/selectLineItem";

export default function Substitute(): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [me, setMe] = useState<MeUser<any> | null>(null);
  const [order, setOrder] = useState<Order<OrderXp, any, any> | null>(null);
  const [product, setProduct] = useState<Product<any> | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product<any> | null>(
    null
  );
  const [showingProduct, setShowingProduct] = useState<boolean>(false);
  const [supplier, setSupplier] = useState<Supplier<any> | null>(null);
  const [selectQuantityValue, setSelectQuantityValue] = useState<string>("1");
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState<Array<Product<any>> | null>(null);
  const [specs, setSpecs] = useState<Array<Spec<any, any>> | null>(null);
  const [selectedSpecs, setSelectedSpecs] = useState<
    Array<{ specId: string; optionId: string }>
  >(Array<{ specId: string; optionId: string }>());
  const [lineItem, setLineItem] = useState<LineItem<
    LineItemXp,
    any,
    VariantXp
  > | null>(null);

  useEffect(() => {
    const searchProducts = async (): Promise<void> => {
      if (search.length <= 3) {
        return;
      }
      await GetProductsBySearchWithCallback(
        search,
        params?.store ?? "",
        searchCallback,
        searchFailureCallback
      );
    };

    const getData = async (): Promise<void> => {
      await GetOrderByIdWithCallback(params.order, callback, failureCallback);
    };

    const getSupplier = async (): Promise<void> => {
      await GetSupplierWithCallback(
        supplierCallback,
        supplierFailureCallback,
        params.store
      );
    };

    const supplierCallback = (supplier: Supplier<any>) => {
      setSupplier(supplier);
    };

    const searchCallback = (p: Array<Product<any>>) => {
      setProducts(p);
    };

    const searchFailureCallback = () => {
      confirmAlert({
        title: "Search failed to return any results.",
        buttons: [
          {
            label: "Ok",
            onClick: async () => {},
          },
        ],
      });
    };

    const supplierFailureCallback = () => {
      confirmAlert({
        title: "Unable to find the supplier.",
        buttons: [
          {
            label: "Ok",
            onClick: async () =>
              navigate(`/store/${params.store}/${params.order}`),
          },
        ],
      });
    };

    const getMe = async (): Promise<void> => {
      await GetMeWithCallback(meCallback);
    };

    const getLineItem = async () => {
      await GetOrderLineItemWithCallback(
        params.lineItem ?? "",
        params.order ?? "",
        async (lineItem: LineItem<LineItemXp, any, VariantXp>) => {
          setLineItem(lineItem);
          setSelectQuantityValue(lineItem.Quantity?.toString() ?? "0");
          await GetProductByIdWithCallback(
            lineItem.ProductID,
            productCallback,
            productFailureCallback
          );
        },
        lineItemFailureCallback
      );
    };

    const getProductSpecs = async () => {
      await GetProductSpecs(
        selectedProduct?.ID ?? "",
        specCallBack,
        specsFailureCallback
      );
    };

    const specCallBack = (s: Array<Spec<any, any>>) => {
      setSpecs(s);
      setSelectedSpecs(
        s
          .filter((spec) => {
            return (
              typeof spec?.Options !== "undefined" && spec?.Options.length > 0
            );
          })
          .map((spec) => {
            return {
              optionId:
                typeof spec.Options !== "undefined"
                  ? spec.Options[0]?.ID ?? ""
                  : "",
              specId: spec.ID ?? "",
            };
          })
      );
    };

    const specsFailureCallback = () => {
      confirmAlert({
        title: "Unable to find the product specs.",
        buttons: [
          {
            label: "Ok",
            onClick: async () =>
              navigate(`/store/${params.store}/${params.order}`),
          },
        ],
      });
    };

    const productCallback = (product: Product<any>) => {
      if (
        typeof product.Inventory === "undefined" ||
        typeof product.Inventory.QuantityAvailable === "undefined"
      ) {
        confirmAlert({
          title: "Product does not have any inventory.",
          buttons: [
            {
              label: "Ok",
              onClick: async () =>
                navigate(`/store/${params.store}/${params.order}`),
            },
          ],
        });
      } else {
        setProduct(product);
      }
    };

    const productFailureCallback = () => {
      confirmAlert({
        title: "Unable to find the product.",
        buttons: [
          {
            label: "Ok",
            onClick: async () =>
              navigate(`/store/${params.store}/${params.order}`),
          },
        ],
      });
    };

    const failureCallback = () => {
      confirmAlert({
        title: "Unable to find the order.",
        buttons: [
          {
            label: "Ok",
            onClick: async () =>
              navigate(`/store/${params.store}/${params.order}`),
          },
        ],
      });
    };

    const lineItemFailureCallback = () => {
      confirmAlert({
        title: "Unable to find the line item.",
        buttons: [
          {
            label: "Ok",
            onClick: async () =>
              navigate(`/store/${params.store}/${params.order}`),
          },
        ],
      });
    };

    const meCallback = (me: MeUser<any>) => {
      setMe(me);
    };

    const callback = async (order: Order<OrderXp, any, any>) => {
      if (
        order?.xp?.Assignment?.AssignedTo !== me?.ID &&
        order?.Status !== "Open"
      ) {
        confirmAlert({
          title: "You cannot modify the quantity on this line item.",
          buttons: [
            {
              label: "Ok",
              onClick: async () =>
                navigate(`/store/${params.store}/${params.order}`),
            },
          ],
        });
      } else {
        setOrder(order);
      }
    };

    if (typeof me === "undefined" || me === null) {
      getMe();
    }

    if (typeof lineItem === "undefined" || lineItem === null) {
      getLineItem();
    }

    if (typeof order === "undefined" || order === null) {
      getData();
    }

    if (typeof supplier === "undefined" || supplier === null) {
      getSupplier();
    }

    if (search.length > 3) {
      if (products === null) {
        searchProducts();
      }
    } else {
      setProducts(null);
      setSelectedProduct(null);
      setSpecs(null);
      setSelectedSpecs(Array<{ specId: string; optionId: string }>());
    }

    if (selectedProduct !== null) {
      getProductSpecs();
    }
  }, [products, search, selectedProduct, specs]);

  if (lineItem === null) return <></>;

  if (lineItem.xp?.Picked) {
    confirmAlert({
      title: "You cannot change the quantity on an already picked line",
      buttons: [
        {
          label: "Ok",
          onClick: async () =>
            navigate(`/store/${params.store}/${params.order}`),
        },
      ],
    });
  }

  const switchLineItems = async (
    quantity: number,
    product: Product<any>,
    specs: Array<{ specId: string; optionId: string }>
  ) => {
    await AddLineItemWithCallback(
      params.order ?? "",
      product?.ID ?? "",
      quantity,
      specs,
      addLineItemCallback,
      addLineItemFailureCallback
    );
  };

  const addLineItemCallback = async (
    line: LineItem<LineItemXp, any, VariantXp>
  ) => {
    await SaveNoteToOrderXp(
      `Line item ${line?.Product?.Name ?? ""} added to order ${
        params.order
      } by ${me?.FirstName} ${me?.LastName}`,
      order?.ID ?? ""
    );
    await DeleteLineItemWithCallback(
      params.order ?? "",
      lineItem?.ID ?? "",
      deleteLineItemCallback,
      addLineItemFailureCallback
    );
  };

  const deleteLineItemCallback = async () => {
    await SaveNoteToOrderXp(
      `Line item ${lineItem?.Product?.Name ?? ""} removed from order ${
        params.order
      } by ${me?.FirstName} ${me?.LastName}`,
      order?.ID ?? ""
    );

    navigate(`/store/${params.store}/${params.order}`);
  };

  const addLineItemFailureCallback = () => {
    confirmAlert({
      title: "Failed to update line item.",
      buttons: [
        {
          label: "Ok",
          onClick: async () => setShowSpinner(false),
        },
      ],
    });
  };

  return (
    <div className="flex min-h-screen flex-col">
      <Header
        title="Substitute"
        backLink={`/store/${params.store}/${params.order}`}
      />
      <div className="ml-2 min-h-fit mb-4 mr-2">
        <Title
          title={supplier?.Name}
          subTitle={`Order: ${order?.ID} - ${lineItem.Product?.Name ?? ""} x ${
            lineItem.Quantity ?? ""
          }`}
        />
        <LineItemComponent listItem={lineItem} />
        <div className="mt-2"></div>

        <SelectLineItem
          setShowingDetails={setShowingProduct}
          buttonText={`Substitute {selectedProductName} for ${
            product?.Name ?? ""
          }`}
          quantitySelected={parseInt(selectQuantityValue)}
          setShowSpinner={setShowSpinner}
          showSpinner={showSpinner}
          store={params?.store ?? ""}
          specsFailureCallback={() => {
            confirmAlert({
              title: "Unable to find the product specs.",
              buttons: [
                {
                  label: "Ok",
                  onClick: async () =>
                    navigate(`/store/${params.store}/${params.order}`),
                },
              ],
            });
          }}
          selectLineItemCallback={(
            quantity: number,
            product: Product<any>,
            specs: Array<{ specId: string; optionId: string }>
          ) => {
            if (showSpinner) return;
            confirmAlert({
              title: "Are you sure you want to swap these line items?",
              buttons: [
                {
                  label: "No, nevermind.",
                  onClick: async () => {},
                },
                {
                  label: "Yes, I am sure.",
                  onClick: async () => {
                    setShowSpinner(true);
                    await switchLineItems(quantity, product, specs);
                  },
                },
              ],
            });
          }}
        ></SelectLineItem>
      </div>
      <BottomButton
        buttonText="Cancel"
        onClickCallback={() => {
          navigate(`/store/${params.store}/${params.order}`);
        }}
      ></BottomButton>
      <Footer selected="Home"></Footer>
    </div>
  );
}
