import { Buyers, Supplier } from "ordercloud-javascript-sdk";

export const GetBuyerSupplierWithCallback = async (
  buyerId: string,
  callback: (suppliers: Array<Supplier<any>>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  Buyers.ListBuyerSellers(buyerId)
    .then((response) => {
      if (logging) {
        console.log(response);
      }
      if (typeof callback !== "undefined") {
        callback(response.Items);
      } else {
        console.log("Invalid or no callback provided for GetBuyerSupplierWithCallback");
      }
    })
    
    .catch((err) => {
      console.log(err);
      failureCallback();
    });
};
