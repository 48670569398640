import { Link } from "react-router-dom";
import { RoundButtonProps } from "../../types/roundButtonProps";
import Icon from "react-icons-kit";
import { chevronRight } from "react-icons-kit/feather/chevronRight";

export default function RoundButton(props: RoundButtonProps): JSX.Element {
  return (
    <Link to={props.url} title={props.text}>
      <div>
        <span>
          <button
            className={`inline-flex items-center justify-center w-4 h-4 mr-2 transition-colors duration-150 rounded-full focus:shadow-outline mb-1 ${
              props.iconTextColor
            } ${props.iconColor} hover:${
              props.iconHoverColor ?? props.iconColor
            }`}
          >
            <Icon icon={props.icon} size={18} />
          </button>
        </span>
        <span className="text-xl">{props.text}</span>
        <span className="float-right mr-2 pt-1">
          <Icon icon={chevronRight} size={16} />
        </span>
      </div>
    </Link>
  );
}
