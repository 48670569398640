/* eslint-disable react-hooks/exhaustive-deps */
import { Product, Spec } from "ordercloud-javascript-sdk";
import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import Icon from "react-icons-kit";
import { Capitalize } from "../../lib/textHelps";
import ProductItemComponent from "./productItemComponent";
import { barcode } from "react-icons-kit/fa/barcode";
import { close } from "react-icons-kit/fa/close";
import { SelectLineItemProps } from "../../types/selectLineItemProps";
import {
  GetProductsBySearchWithCallback,
  GetProductSpecs,
} from "../../lib/productHelper";
import { Html5QrcodeResult, Html5QrcodeScanner } from "html5-qrcode";
import { Html5QrcodeError } from "html5-qrcode/esm/core";
import Modal from "react-modal";

export default function SelectLineItem(
  props: SelectLineItemProps
): JSX.Element {
  const [selectQuantityValue, setSelectQuantityValue] = useState<string>("1");
  const [selectValue, setSelectValue] = useState<string | null>(null);
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState<Array<Product<any>> | null>(null);
  const [specs, setSpecs] = useState<Array<Spec<any, any>> | null>(null);
  const [selectedSpecs, setSelectedSpecs] = useState<
    Array<{ specId: string; optionId: string }>
  >(Array<{ specId: string; optionId: string }>());
  const [selectedProduct, setSelectedProduct] = useState<Product<any> | null>(
    null
  );

  const customStyles = {
    content: {
      top: "auto",
      left: "auto",
      right: "auto",
      bottom: "auto",
      width: "100%",
      height: "100%",
    },
    overlay: { zIndex: 1000 },
  };

  const [scanner, setScanner] = useState<Html5QrcodeScanner | null>(null);
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);

  const handleSearch = () => {
    setProducts(null);
    setSelectValue(null);
    setSelectedProduct(null);
    setSpecs(null);
    setSelectedSpecs(Array<{ specId: string; optionId: string }>());
    props.setShowingDetails(false);
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    const searchProducts = async (): Promise<void> => {
      if (search.length <= 3) {
        return;
      }
      await GetProductsBySearchWithCallback(
        search,
        props.store,
        searchCallback,
        searchFailureCallback
      );
    };

    const searchCallback = (p: Array<Product<any>>) => {
      setProducts(p);
    };

    const searchFailureCallback = () => {
      confirmAlert({
        title: "Search failed to return any results.",
        buttons: [
          {
            label: "Ok",
            onClick: async () => {},
          },
        ],
      });
    };

    const getProductSpecs = async () => {
      await GetProductSpecs(
        selectedProduct?.ID ?? "",
        specCallBack,
        specsFailureCallback
      );
    };

    const specCallBack = (s: Array<Spec<any, any>>) => {
      setSpecs(s);
      setSelectedSpecs(
        s
          .filter((spec) => {
            return (
              typeof spec?.Options !== "undefined" && spec?.Options.length > 0
            );
          })
          .map((spec) => {
            return {
              optionId:
                typeof spec.Options !== "undefined"
                  ? spec.Options[0]?.ID ?? ""
                  : "",
              specId: spec.ID ?? "",
            };
          })
      );
    };

    const specsFailureCallback = () => {
      props.specsFailureCallback();
    };

    if (search.length > 3) {
      if (products === null) {
        searchProducts();
      }
    } else {
      setProducts(null);
      setSelectValue(null);
      setSelectedProduct(null);
      setSpecs(null);
      setSelectedSpecs(Array<{ specId: string; optionId: string }>());
      props.setShowingDetails(false);
    }

    if (selectedProduct !== null) {
      getProductSpecs();
    }
  }, [search, selectedProduct, specs, selectValue]);

  const getSelectItems = (): JSX.Element[] => {
    if (
      typeof selectedProduct?.Inventory === "undefined" ||
      typeof selectedProduct?.Inventory.QuantityAvailable === "undefined"
    ) {
      return [
        <option value={props.quantitySelected}>
          {props.quantitySelected}
        </option>,
      ];
    }
    const options = Array<JSX.Element>();

    for (
      let index = 0;
      index <=
      selectedProduct.Inventory.QuantityAvailable + props.quantitySelected;
      index++
    ) {
      options.push(<option value={index}>{index}</option>);
    }

    return options;
  };

  function closeModal() {
    setIsOpen(false);
  }

  if (
    selectValue === null &&
    products !== null &&
    products.length === 1 &&
    products[0] !== null
  ) {
    setSelectValue(products[0]?.ID ?? "");
    setSelectedProduct(products[0]);
    setSpecs(null);
    setSelectedSpecs(Array<{ specId: string; optionId: string }>());
    props.setShowingDetails(true);
  }

  return (
    <>
      <div className="flex">
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-400 leading-tight focus:outline-none focus:shadow-outline max-h-2 mt-1"
          id="Search for a product"
          type="text"
          placeholder="Search"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          value={search}
          onKeyDown={handleKeyDown}
        />
        <span
          className="flex justify-around items-center"
          onClick={() => {
            setSearch("");
            setSelectValue(null);
            setSelectedProduct(null);
            setSpecs(null);
            setSelectedSpecs(Array<{ specId: string; optionId: string }>());
            setProducts(null);
            setIsOpen(true);
          }}
        >
          <Icon
            className="absolute mr-5 mt-1 cursor-pointer"
            icon={barcode}
            size={14}
          />
        </span>
      </div>
      {search !== "" && products !== null && products.length > 0 && (
        <>
          <div className="mt-2"></div>
          <label
            htmlFor="productSelect"
            className="block text-grey-400 text-sm font-bold mb-0"
          >
            Select a product ({products.length}):
          </label>
          <select
            id="productSelect"
            name="productSelect"
            className="bg-white border-b px-0 text-grey-400 focus:outline-none focus:shadow-outline block w-full p-2.5 dark:placeholder-black dark:black h-3 pl-0 mt-1"
            onChange={async (value) => {
              if (value.target.value === "") {
                setSelectValue("");
                setSelectedProduct(null);
                setSpecs(null);
                setSelectedSpecs(Array<{ specId: string; optionId: string }>());
                props.setShowingDetails(false);
              } else {
                const prod =
                  products.find((p) => {
                    return p.ID === value.target.value;
                  }) ?? null;
                setSelectedProduct(prod);
                setSelectValue(value.target.value);
                setSpecs(null);
                setSelectedSpecs(Array<{ specId: string; optionId: string }>());
                props.setShowingDetails(true);
              }
            }}
            value={selectValue ?? ""}
          >
            <option value="">Select a Product</option>
            {products.map((p, i) => {
              return (
                <option value={p.ID} key={i}>
                  {p.Name}
                </option>
              );
            })}
          </select>
        </>
      )}
      {search !== "" && products !== null && products.length === 0 && (
        <>
          <img src={"/assets/img/empty.gif"} alt="not found"></img>
          <div className="w-full text-center text-lg">No Products Found.</div>
        </>
      )}

      {selectedProduct !== null && specs !== null && (
        <>
          <ProductItemComponent product={selectedProduct} specs={specs} />
          <div className="mt-2"></div>
          <label
            htmlFor="quantitySelect"
            className="block text-grey-400 text-sm font-bold mb-0 "
          >
            Quantity
          </label>
          <select
            disabled={props.showSpinner}
            id="quantitySelect"
            name="quantitySelect"
            className="bg-white border-b px-0 text-grey-400 focus:outline-none focus:shadow-outline block w-full p-2.5 dark:placeholder-black dark:black h-3 pl-0 mt-1"
            onChange={async (value) => {
              setSelectQuantityValue(value.target.value);
            }}
            value={selectQuantityValue}
          >
            {getSelectItems()}
          </select>
          <div className="mt-2"></div>
          {specs?.map((s, i) => {
            return (
              <div key={i}>
                <label
                  htmlFor={`spec-${s.ID ?? ""}`}
                  className="block text-grey-400 text-sm font-bold mb-0"
                >
                  {s.Name}
                </label>
                <select
                  disabled={props.showSpinner}
                  id={`spec-${s.ID ?? ""}`}
                  className="bg-white border-b px-0 text-grey-400 focus:outline-none focus:shadow-outline block w-full p-2.5 dark:placeholder-black dark:black h-3 pl-0 mt-1"
                  onChange={async (value) => {
                    const mySpecs = selectedSpecs.map((spec) => {
                      return spec.specId === s.ID
                        ? { specId: s.ID, optionId: value.target.value }
                        : spec;
                    });
                    setSelectedSpecs(mySpecs);
                  }}
                  value={
                    selectedSpecs.find((spec) => {
                      return spec.specId === s.ID;
                    })?.optionId
                  }
                >
                  {s.Options?.map((o, ii) => {
                    return (
                      <option key={ii} value={o.ID}>
                        {Capitalize(o.Value ?? "")}
                      </option>
                    );
                  })}
                </select>
                <div className="mt-2"></div>
              </div>
            );
          })}
        </>
      )}
      {selectedProduct !== null && (
        <button
          disabled={props.showSpinner}
          className={`text-black font-bold py-1 px-4 rounded-lg flex justify-center ${
            props.showSpinner
              ? "bg-grey-200 hover:bg-grey-200 cursor-not-allowed  border-grey-200 "
              : "bg-white hover:bg-white border-black cursor-pointer"
          } border-1 w-full mt-1`}
          type="button"
          onClick={async () => {
            if (props.showSpinner) return;
            const qty = selectQuantityValue;
            const prod = selectedProduct;
            const spec = selectedSpecs;
            props.setShowSpinner(true);
            props.selectLineItemCallback(parseInt(qty), prod, spec);
            if (props.clearAfterButtonPress ?? false) {
              setProducts(null);
              setSelectValue("");
              setSelectedProduct(null);
              setSpecs(null);
              setSelectedSpecs(Array<{ specId: string; optionId: string }>());
              props.setShowingDetails(false);
            }
            props.setShowSpinner(false);
          }}
        >
          {props.showSpinner ? (
            <div className="">
              <svg
                aria-hidden="true"
                className="w-2 h-2 mr-2 ml-1 text-white animate-spin dark:text-white fill-orange text-center"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Please wait</span>
            </div>
          ) : (
            <div className="w-full h-2">
              {props.buttonText.replace(
                "{selectedProductName}",
                selectedProduct?.Name ?? ""
              )}
            </div>
          )}
        </button>
      )}

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => {
          if (scanner === null) {
            const s = new Html5QrcodeScanner(
              "qr-reader",
              { fps: 10, qrbox: 250 },
              true
            );

            s.render(
              (decodedText: string, result: Html5QrcodeResult) => {
                setSearch(decodedText);
                setIsOpen(false);
              },
              (errorMessage: string, error: Html5QrcodeError) => {}
            );

            setScanner(s);
          }
        }}
        onAfterClose={() => {
          if (scanner !== null) {
            scanner.clear();
            setScanner(null);
          }
        }}
        onRequestClose={closeModal}
        contentLabel="Bar Code Scanner"
        style={customStyles}
      >
        <div
          className="mb-3 "
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <Icon
            className="cursor-pointer absolute right-1"
            icon={close}
            size={14}
          />
        </div>
        <div className="">
          <div id="qr-reader"></div>
        </div>
      </Modal>
    </>
  );
}
