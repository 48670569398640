import { HeaderProps } from "../types/headerProps";

export default function LoginHeader(props: HeaderProps): JSX.Element {
 
 
  return (
    <header>
      <nav>
        <div className="mx-auto px-1 sm:px-2 lg:px-2 relative z-50 justify-between py-6 bg-black text-white font-bold text-center grid grid-cols-12">
        <div className="text-xl col-span-1"></div>
          <div className="text-xl col-span-5">
            <img src="/assets/img/alpha-logo-white.png" alt="alpha"/>
          </div>
          <div
            className="text-4xl col-span-6 text-left"
            dangerouslySetInnerHTML={{ __html: props.title }}
          ></div>
        </div>
      </nav>
    </header>
  );
}
