import { Route, Routes } from "react-router-dom";
import Home from "./pages/secure/home";
import NotFound from "./pages/notfound";
import Login from "./pages/login";
import { Tokens } from "ordercloud-javascript-sdk";
import Store from "./pages/secure/store/[store]/store";
import PrepareOrders from "./pages/secure/store/[store]/prepare-orders";
import PickList from "./pages/secure/store/[store]/[order]/pick-list";
import Substitute from "./pages/secure/store/[store]/[order]/[lineItem]/substitute-line-item";
import Short from "./pages/secure/store/[store]/[order]/[lineItem]/short-line-item";
import LoginBuyer from "./pages/login-buyer";
import CreateOrder from "./pages/secure/create-order";
import ConfirmDelivery from "./pages/secure/store/[store]/confirm-delivery";
import ConfirmOrders from "./pages/secure/store/[store]/confirm-orders";
import ProductSearch from "./pages/secure/search";

const Main = () => {
  return (
    <Routes>
      {/* The Switch decides which component to show based on the current URL.*/}
      <Route
        path="/"
        Component={!Tokens.GetAccessToken() ? Login : Home}
      ></Route>
      <Route
        path="/login-buyer"
        Component={!Tokens.GetAccessToken() ? LoginBuyer : CreateOrder}
      ></Route>
       <Route
        path="/search"
        Component={!Tokens.GetAccessToken() ? Login : ProductSearch}
      ></Route>
      <Route
        path="/create-order"
        Component={!Tokens.GetAccessToken() ? LoginBuyer : CreateOrder}
      ></Route>
       <Route
        path="/store/:store"
        Component={!Tokens.GetAccessToken() ? Login : Store}
      ></Route>
      <Route
        path="/store/:store/prepare-orders"
        Component={!Tokens.GetAccessToken() ? Login : PrepareOrders}
      ></Route>
       <Route
        path="/store/:store/confirm-delivery"
        Component={!Tokens.GetAccessToken() ? Login : ConfirmDelivery}
      ></Route>
       <Route
        path="/store/:store/confirm-orders"
        Component={!Tokens.GetAccessToken() ? Login : ConfirmOrders}
      ></Route>
      <Route
        path="/store/:store/:order"
        Component={!Tokens.GetAccessToken() ? Login : PickList}
      ></Route>
      <Route
        path="/store/:store/:order/:lineItem/substitute-line-item"
        Component={!Tokens.GetAccessToken() ? Login : Substitute}
      ></Route>
      <Route
        path="/store/:store/:order/:lineItem"
        Component={!Tokens.GetAccessToken() ? Login : Short}
      ></Route>
      <Route path="/notfound" Component={NotFound}></Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Main;
