import { Product, Products, Spec } from "ordercloud-javascript-sdk";

export const GetProductByIdWithCallback = async (
  productId: string,
  callback: (product: Product<any>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Products.Get(productId)
    .then((product) => {
      if (logging) {
        console.log(product);
      }
      if (typeof callback !== "undefined") {
        callback(product);
      } else {
        console.log(
          "Invalid or no callback provided for GetProductByIdWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
      if (typeof failureCallback !== "undefined") {
        failureCallback();
      }
    });
};

export const GetProductSpecs = async (
  productId: string,
  callback: (product: Array<Spec<any, any>>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Products.ListSpecs(productId)
    .then((specs) => {
      if (logging) {
        console.log(specs);
      }
      if (typeof callback !== "undefined") {
        callback(specs.Items);
      } else {
        console.log(
          "Invalid or no callback provided for GetProductByIdWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
      if (typeof failureCallback !== "undefined") {
        failureCallback();
      }
    });
};

export const GetProductsBySearchWithCallback = async (
  search: string,
  supplierId: string,
  callback: (products: Array<Product<any>>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Products.List({
    supplierID: supplierId,
    search: search,
    searchOn: [
      "ID",
      "Name",
      "xp.StyleNumber",
      "xp.GiftFinder.Occaision",
      "xp.GiftFinder.Personality",
      "xp.GiftFinder.Recipient",
      "Description",
      "xp.LongDescription",
      "xp.Brand",
    ],
    searchType: "AllTermsAnyField",
    pageSize: 100,
  })
    .then((products) => {
      if (logging) {
        console.log(products);
      }
      if (typeof callback !== "undefined") {
        callback(products.Items);
      } else {
        console.log(
          "Invalid or no callback provided for GetProductsBySearchWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
      if (typeof failureCallback !== "undefined") {
        failureCallback();
      }
    });
};

export const GetProductsBySearchWithoutSupplierWithCallback = async (
  search: string,
  callback: (products: Array<Product<any>>) => void,
  failureCallback: () => void,
  logging: boolean = false
) => {
  await Products.List({
    search: search,
    searchOn: [
      "ID",
      "Name",
      "xp.StyleNumber",
      "xp.GiftFinder.Occaision",
      "xp.GiftFinder.Personality",
      "xp.GiftFinder.Recipient",
      "Description",
      "xp.LongDescription",
      "xp.Brand",
    ],
    searchType: "AllTermsAnyField",
    pageSize: 100,
  })
    .then((products) => {
      if (logging) {
        console.log(products);
      }
      if (typeof callback !== "undefined") {
        callback(products.Items);
      } else {
        console.log(
          "Invalid or no callback provided for GetProductsBySearchWithoutSupplierWithCallback"
        );
      }
    })
    .catch((err) => {
      console.log(err);
      if (typeof failureCallback !== "undefined") {
        failureCallback();
      }
    });
};
