import { useForm } from "react-hook-form";
import Header from "../../header";
import Title from "../../shared/title";
import { Fragment, useEffect, useState } from "react";
import {
  LineItem,
  Order,
  Payment,
  Product,
  Supplier,
} from "ordercloud-javascript-sdk";
import { GetBuyerSupplierWithCallback } from "../../../lib/buyerHelper";
import {
  LineItemXp,
  OrderXp,
  PaymentXp,
  VariantXp,
} from "../../../types/OrderCloud/xp";
import moment from "moment";
import { FirstLetterOfEachWord } from "../../../lib/textHelps";
import BottomButton from "../../shared/bottomButton";
import InputMask from "react-input-mask";

import {
  AddLineItemWithCallback,
  AddPaymentWithCallback,
  CreateBillingAddressWithCallback,
  CreateOrderWithCallback,
  SubmitOrderWithCallback,
} from "../../../lib/orderHelper";
import { confirmAlert } from "react-confirm-alert";
import SelectLineItem from "../../shared/selectLineItem";
import Icon from "react-icons-kit";
import { square_delete as deleteIconic } from "react-icons-kit/ikons/square_delete";
import PoweredBy from "../../shared/poweredBy";
import { AddressAutofill } from "@mapbox/search-js-react";
import { AddressAutofillRetrieveResponse } from "@mapbox/search-js-core";

export default function CreateOrder(): JSX.Element {
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ mode: "onBlur", reValidateMode: "onChange" });

  const [search, setSearch] = useState("");
  const [selectValue, setSelectValue] = useState<string>("watertown-supplier");

  const [lineItems, setLineItems] = useState<Array<{
    quantity: number;
    product: Product<any>;
    specs: Array<{ specId: string; optionId: string }>;
  }> | null>(null);

  const [processingOrderCreation, setProcessingOrderCreation] =
    useState<boolean>(false);

  const [showingProduct, setShowingProduct] = useState<boolean>(false);

  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [suppliers, setSuppliers] = useState<Array<Supplier<any>> | null>(null);

  const [order, setOrder] = useState<Order<OrderXp, any, any> | null>(null);

  const [orderId, setOrderId] = useState<string>("");
  const [fulfillment, setFulfillment] = useState<string>("P");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [country, setCountry] = useState<string>("US");

  const [finished, setFinished] = useState<boolean>(false);

  const [billingAddressCreated, setBillingAddressCreated] =
    useState<boolean>(false);

  const [lineItemsCreated, setLineItemsCreated] = useState<boolean>(false);

  async function finishOrder() {
    await AddPaymentWithCallback(
      order?.ID ?? "",
      async (payment: Payment<PaymentXp>) => {
        await SubmitOrderWithCallback(
          order?.ID ?? "",
          (order: Order<OrderXp, any, any>) => {
            setProcessingOrderCreation(false);
            setFinished(true);
          },
          async () => {}
        );
      },
      () => {}
    );
  }

  async function createOrder() {
    if (processingOrderCreation) return;
    setProcessingOrderCreation(true);
    await CreateOrderWithCallback(
      orderId,
      "attraqt-buyer",
      selectValue,
      fulfillment === "P" ? "PickUp" : "Delivery",
      (order: Order<OrderXp, any, any>) => {
        setOrder(order);
        setProcessingOrderCreation(false);
      },
      () => {
        confirmAlert({
          title: "Order Creation failed?",
          buttons: [
            {
              label: "Ok.",
              onClick: async () => {
                setProcessingOrderCreation(false);
              },
            },
          ],
        });
      }
    );
  }

  async function addAddress() {
    if (processingOrderCreation) return;
    setProcessingOrderCreation(true);
    await CreateBillingAddressWithCallback(
      order?.ID ?? "",
      firstName,
      lastName,
      phone,
      street,
      city,
      state,
      zip,
      country,
      (order: Order<OrderXp, any, any>) => {
        setOrder(order);
        setBillingAddressCreated(true);
        setProcessingOrderCreation(false);
      },
      () => {
        confirmAlert({
          title: "Address Creation failed?",
          buttons: [
            {
              label: "Ok.",
              onClick: async () => {
                setProcessingOrderCreation(false);
              },
            },
          ],
        });
      }
    );
  }

  async function addLineItems() {
    if (processingOrderCreation) return;
    setProcessingOrderCreation(true);
    lineItems?.map(async (li) => {
      await AddLineItemWithCallback(
        order?.ID ?? "",
        li.product?.ID ?? "",
        li.quantity,
        li.specs,
        (lineItem: LineItem<LineItemXp, any, VariantXp>) => {
          setLineItemsCreated(true);
          setProcessingOrderCreation(false);
        },
        () => {
          confirmAlert({
            title: "Line Item Creation failed?",
            buttons: [
              {
                label: "Ok.",
                onClick: async () => {
                  setProcessingOrderCreation(false);
                },
              },
            ],
          });
        }
      );
    });
  }

  const deleteLineItem = (lineNumber: number) => {
    confirmAlert({
      title: "Do you want to delete this line item",
      buttons: [
        {
          label: "No, nevermind",
        },
        {
          label: "Yes, I want to delete it.",
          onClick: async () => {
            const localLineItems =
              lineItems?.filter((f, i) => {
                return i !== lineNumber;
              }) ??
              Array<{
                quantity: number;
                product: Product<any>;
                specs: Array<{ specId: string; optionId: string }>;
              }>();
            setLineItems(localLineItems);
          },
        },
      ],
    });
  };

  const handleChange = (e: any) => {
    e.persist();
    setValue(e.target.name, e.target.value);
    trigger(e.target.name);
  };

  useEffect(() => {
    const setId = () => {
      const company =
        suppliers?.find((s) => {
          return s.ID === selectValue;
        }) ?? null;
      setOrderId(
        `${fulfillment}${moment().format(
          "YYYYMMDDHHmm"
        )}${FirstLetterOfEachWord(company?.Name ?? "")}${FirstLetterOfEachWord(
          firstName
        )}${FirstLetterOfEachWord(lastName)}`.toUpperCase()
      );
    };

    const getSuppliers = async (): Promise<void> => {
      await GetBuyerSupplierWithCallback(
        "attraqt-buyer",
        getSuppliersCallback,
        () => {}
      );
    };

    const getSuppliersCallback = (suppliers: Array<Supplier<any>>) => {
      setSuppliers(suppliers);
    };

    if (suppliers === null) {
      getSuppliers();
    }
    if (order === null) {
      setId();
    }
  }, [
    suppliers,
    firstName,
    lastName,
    fulfillment,
    selectValue,
    processingOrderCreation,
    billingAddressCreated,
    order,
    search,
    lineItems,
    showSpinner,
    lineItemsCreated,
  ]);

  if (finished) {
    setSearch("");
    setSelectValue("watertown-supplier");
    setLineItems(null);
    setProcessingOrderCreation(false);
    setShowSpinner(false);
    setSuppliers(null);
    setOrder(null);

    setOrderId("");
    setFulfillment("P");
    setFirstName("");
    setLastName("");
    setPhone("");
    setStreet("");
    setCity("");
    setState("");
    setZip("");
    setCountry("US");

    setFinished(false);

    setBillingAddressCreated(false);

    setLineItemsCreated(false);
  }

  return (
    <div className="flex min-h-full flex-col">
      <Header title="Create Order" />
      <form
        className="bg-white  px-3 pt-1 mb-4"
        onSubmit={handleSubmit(
          order === null ? createOrder : addAddress,
          () => {
            setProcessingOrderCreation(false);
            clearErrors("login");
          }
        )}
      >
        <div className="ml-2 min-h-fit mb-4 ">
          {order === null && (
            <>
              <Title title="The Order" />
              <div className="mb-1">
                <label
                  className="block text-grey-400 text-sm font-bold mb-0"
                  htmlFor="orderid"
                >
                  Order Id
                </label>
                <input
                  className="appearance-none border-b  w-full py-1 px-0 text-grey-400 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                  id="orderid"
                  type="text"
                  placeholder="Order ID"
                  autoComplete="none"
                  value={orderId}
                  readOnly={true}
                />
              </div>

              <div className="mb-1">
                <label
                  className="block text-grey-400 text-sm font-bold mb-0"
                  htmlFor="fromCompany"
                >
                  From Company (Buyer)
                </label>
                <input
                  className="appearance-none border-b  w-full py-1 px-0 text-grey-400 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                  id="fromCompany"
                  type="text"
                  placeholder="From Company"
                  autoComplete="none"
                  defaultValue="attraqt-buyer"
                  readOnly={true}
                />
              </div>
              <div className="mb-1">
                <label
                  htmlFor="supplierSelect"
                  className="block text-grey-400 text-sm font-bold mb-0 "
                >
                  To Company (Supplier)
                </label>
                <select
                  id="supplierSelect"
                  name="supplierSelect"
                  className="bg-white border-b px-0 text-grey-400 focus:outline-none focus:shadow-outline block w-full p-2.5 dark:placeholder-black dark:black h-3 pl-0 mt-1"
                  onChange={async (value) => {
                    setSelectValue(value.target.value);
                  }}
                  value={selectValue}
                >
                  {suppliers !== null &&
                    suppliers.map((s, i) => {
                      return (
                        <option value={s.ID} key={i}>
                          {s.Name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="mb-1">
                <label
                  className="block text-grey-400 text-sm font-bold mb-0"
                  htmlFor="firstName"
                >
                  First Name
                </label>
                <input
                  className="appearance-none border-b  w-full py-1 px-0 text-grey-400 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                  id="firstName"
                  type="text"
                  {...register("firstName", {
                    required: true,
                  })}
                  placeholder="First Name"
                  onBlur={handleChange}
                  autoComplete="none"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />

                {errors.firstName && errors.firstName.type === "required" && (
                  <p className="text-red text-xs italic">
                    You must enter a first name
                  </p>
                )}
              </div>
              <div className="mb-1">
                <label
                  className="block text-grey-400 text-sm font-bold mb-0"
                  htmlFor="lastName"
                >
                  Last Name
                </label>
                <input
                  className="appearance-none border-b  w-full py-1 px-0 text-grey-400 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                  id="lastName"
                  type="text"
                  {...register("lastName", {
                    required: true,
                  })}
                  placeholder="Last Name"
                  onBlur={handleChange}
                  autoComplete="none"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />

                {errors.lastName && errors.lastName.type === "required" && (
                  <p className="text-red text-xs italic">
                    You must enter a last name
                  </p>
                )}
              </div>
              <div className="mb-1">
                <div className="flex items-center">
                  <input
                    className="w-2 h-2 text-blue-200 bg-grey-100 border-grey-300 focus:ring-blue-200 dark:focus:ring-blue-100 dark:ring-offset-grey-400 focus:ring-2 dark:bg-grey-300 dark:border-grey-200"
                    type="radio"
                    name="radioFulfillment"
                    id="radioPickUp"
                    value="P"
                    checked={fulfillment === "P"}
                    onChange={(e) => {
                      setFulfillment(e.target.value);
                    }}
                  />
                  <label
                    className="block text-grey-400 text-sm font-bold mb-0 ml-1 mt-1"
                    htmlFor="radioPickUp"
                  >
                    Pick Up
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    className="w-2 h-2 text-blue-200 bg-grey-100 border-grey-300 focus:ring-blue-200 dark:focus:ring-blue-100 dark:ring-offset-grey-400 focus:ring-2 dark:bg-grey-300 dark:border-grey-200"
                    type="radio"
                    name="radioFulfillment"
                    id="radioDelivery"
                    value="D"
                    checked={fulfillment === "D"}
                    onChange={(e) => {
                      setFulfillment(e.target.value);
                    }}
                  />
                  <label
                    className="block text-grey-400 text-sm font-bold  mb-1 ml-1 mt-1"
                    htmlFor="radioDelivery"
                  >
                    Delivery
                  </label>
                </div>
              </div>
            </>
          )}

          {order !== null && billingAddressCreated === false && (
            <>
              <Title title={`The Customer for order ${order.ID}`} />

              <div className="mb-1">
                <label
                  className="block text-grey-400 text-sm font-bold mb-0"
                  htmlFor="firstNameRO"
                >
                  First Name
                </label>
                <input
                  className="appearance-none border-b  w-full py-1 px-0 text-grey-400 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                  id="firstNameRO"
                  type="text"
                  placeholder="First Name"
                  autoComplete="none"
                  value={firstName}
                  readOnly={true}
                />
              </div>
              <div className="mb-1">
                <label
                  className="block text-grey-400 text-sm font-bold mb-0"
                  htmlFor="lastNameRO"
                >
                  Last Name
                </label>
                <input
                  className="appearance-none border-b  w-full py-1 px-0 text-grey-400 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                  id="lastNameRO"
                  type="text"
                  placeholder="Last Name"
                  autoComplete="none"
                  value={lastName}
                  readOnly={true}
                />
              </div>

              <div className="mb-1">
                <label
                  className="block text-grey-400 text-sm font-bold mb-0"
                  htmlFor="phone"
                >
                  Phone
                </label>
                <InputMask
                  mask="(999) 999-9999"
                  maskChar=" "
                  alwaysShowMask={true}
                  className="appearance-none border-b  w-full py-1 px-0 text-grey-400 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                  id="phone"
                  type="text"
                  {...register("phone", {
                    required: true,
                    pattern: /^\([0-9]{3}\)[\s]{1}[0-9]{3}-[0-9]{4}$/,
                  })}
                  placeholder="Phone"
                  onBlur={handleChange}
                  autoComplete="none"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />

                {errors.phone && errors.phone.type === "required" && (
                  <p className="text-red text-xs italic">
                    You must enter a phone number
                  </p>
                )}
                {errors.phone && errors.phone.type === "pattern" && (
                  <p className="text-red text-xs italic">
                    Please enter a valid phone number
                  </p>
                )}
              </div>
              <div className="mb-1">
                <label
                  className="block text-grey-400 text-sm font-bold mb-0"
                  htmlFor="street"
                >
                  Street
                </label>
                <AddressAutofill
                  accessToken="pk.eyJ1IjoiandzYWRsZXIiLCJhIjoiY2xuMG0zY3YxMXJhdzJzcWZ0anExODloZyJ9.Z4-LTm7iuxHeYwKdJhNZoQ"
                  options={{ country: "US", language: "en" }}
                  onRetrieve={(response: AddressAutofillRetrieveResponse) => {
                    if (response.features.length > 0) {
                      setStreet(
                        response.features[0]?.properties?.address_line1 ?? ""
                      );
                      setCity(
                        ((response.features[0]?.properties?.address_level3 ??
                          "") !== ""
                          ? response.features[0]?.properties?.address_level3
                          : (response.features[0]?.properties?.address_level2 ??
                              "") !== ""
                          ? response.features[0]?.properties?.address_level2
                          : "") ?? ""
                      );
                      setState(
                        response.features[0]?.properties?.address_level1 ?? ""
                      );
                      setZip(response.features[0]?.properties?.postcode ?? "");
                      setCountry(
                        response.features[0]?.properties?.country_code?.toUpperCase() ??
                          ""
                      );
                      setValue(
                        "street",
                        response.features[0]?.properties?.address_line1 ?? ""
                      );
                      trigger("street");
                      setValue(
                        "city",
                        ((response.features[0]?.properties?.address_level3 ??
                          "") !== ""
                          ? response.features[0]?.properties?.address_level3
                          : (response.features[0]?.properties?.address_level2 ??
                              "") !== ""
                          ? response.features[0]?.properties?.address_level2
                          : "") ?? ""
                      );
                      trigger("city");
                      setValue(
                        "state",
                        response.features[0]?.properties?.address_level1 ?? ""
                      );
                      trigger("state");
                      setValue(
                        "zip",
                        response.features[0]?.properties?.postcode ?? ""
                      );
                      trigger("zip");
                      setValue(
                        "country",
                        response.features[0]?.properties?.country_code?.toUpperCase() ??
                          ""
                      );
                      trigger("country");
                    }
                  }}
                >
                  <input
                    className="appearance-none border-b  w-full py-1 px-0 text-grey-400 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                    id="street"
                    type="text"
                    {...register("street", {
                      required: true,
                    })}
                    placeholder="Street"
                    onBlur={(e) => {
                      setValue("street", e.target.value);
                      trigger("street");
                    }}
                    value={street}
                    onChange={(e) => {
                      setValue("street", e.target.value);
                      trigger("street");
                      setStreet(e.target.value);
                    }}
                    data-lpignore={true}
                  />
                </AddressAutofill>

                {errors.street && errors.street.type === "required" && (
                  <p className="text-red text-xs italic">
                    You must enter a street
                  </p>
                )}
              </div>
              <div className="mb-1">
                <label
                  className="block text-grey-400 text-sm font-bold mb-0"
                  htmlFor="city"
                >
                  City
                </label>
                <input
                  className="appearance-none border-b  w-full py-1 px-0 text-grey-400 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                  id="city"
                  type="text"
                  {...register("city", {
                    required: true,
                  })}
                  placeholder="City"
                  onBlur={handleChange}
                  value={city}
                  onChange={(e) => {
                    setValue("city", e.target.value);
                    trigger("city");
                    setCity(e.target.value);
                  }}
                />

                {errors.city && errors.city.type === "required" && (
                  <p className="text-red text-xs italic">
                    You must enter a city
                  </p>
                )}
              </div>
              <div className="mb-1">
                <label
                  className="block text-grey-400 text-sm font-bold mb-0"
                  htmlFor="state"
                >
                  State
                </label>
                <input
                  className="appearance-none border-b  w-full py-1 px-0 text-grey-400 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                  id="state"
                  type="text"
                  {...register("state", {
                    required: true,
                  })}
                  placeholder="State"
                  onBlur={handleChange}
                  value={state}
                  onChange={(e) => {
                    setValue("state", e.target.value);
                    trigger("state");
                    setState(e.target.value);
                  }}
                />

                {errors.state && errors.state.type === "required" && (
                  <p className="text-red text-xs italic">
                    You must enter a state
                  </p>
                )}
              </div>
              <div className="mb-1">
                <label
                  className="block text-grey-400 text-sm font-bold mb-0"
                  htmlFor="zip"
                >
                  Zip
                </label>
                <InputMask
                  mask="99999"
                  maskChar=" "
                  alwaysShowMask={true}
                  className="appearance-none border-b  w-full py-1 px-0 text-grey-400 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                  id="zip"
                  type="text"
                  {...register("zip", {
                    required: true,
                  })}
                  placeholder="12345"
                  onBlur={handleChange}
                  value={zip}
                  onChange={(e) => {
                    setValue("zip", e.target.value);
                    trigger("zip");
                    setZip(e.target.value);
                  }}
                />

                {errors.zip && errors.zip.type === "required" && (
                  <p className="text-red text-xs italic">
                    You must enter a zip
                  </p>
                )}
              </div>
              <div className="mb-1">
                <label
                  className="block text-grey-400 text-sm font-bold mb-0"
                  htmlFor="country"
                >
                  Country
                </label>
                <input
                  className="appearance-none border-b  w-full py-1 px-0 text-grey-400 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                  id="country"
                  type="text"
                  {...register("country", {
                    required: true,
                  })}
                  placeholder="Country"
                  onBlur={handleChange}
                  readOnly={true}
                  value={country}
                  onChange={(e) => {
                    setValue("country", e.target.value);
                    trigger("country");
                    setCountry(e.target.value);
                  }}
                />

                {errors.country && errors.country.type === "required" && (
                  <p className="text-red text-xs italic">
                    You must enter a country
                  </p>
                )}
              </div>
            </>
          )}
          {order !== null &&
            billingAddressCreated &&
            lineItemsCreated === false && (
              <>
                <Title title={`The Line Items for ${order?.ID ?? ""}`} />
                <div className="mb-1">
                  <SelectLineItem
                    setShowingDetails={setShowingProduct}
                    clearAfterButtonPress={true}
                    buttonText={`Add to order ${order.ID}`}
                    quantitySelected={1}
                    selectLineItemCallback={(
                      quantity: number,
                      product: Product<any>,
                      specs: Array<{ specId: string; optionId: string }>
                    ) => {
                      const localLineItems =
                        lineItems?.map((li) => {
                          return li;
                        }) ??
                        Array<{
                          quantity: number;
                          product: Product<any>;
                          specs: Array<{ specId: string; optionId: string }>;
                        }>();

                      localLineItems.push({
                        product: product,
                        quantity: quantity,
                        specs: specs,
                      });

                      setLineItems(localLineItems);
                    }}
                    setShowSpinner={setShowSpinner}
                    showSpinner={showSpinner}
                    specsFailureCallback={() => {}}
                    store={selectValue}
                  />
                </div>
                <div className="mb-1">
                  {lineItems?.map((li, i) => {
                    return (
                      <div
                        className="h-4 border round mb-1 pl-1 border-grey-300"
                        key={i}
                      >
                        <span className="inline-block align-middle h-4 mt-1">{`${
                          i + 1
                        }. - ${li.product.Name} Qty: ${li.quantity}`}</span>
                        <Icon
                          icon={deleteIconic}
                          size={14}
                          className="float-right mr-1 mt-1 text-red cursor-pointer"
                          onClick={() => {
                            deleteLineItem(i);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          {order !== null &&
            billingAddressCreated &&
            lineItemsCreated === true &&
            lineItems !== null &&
            lineItems.length > 0 && (
              <>
                <Title title={`Complete order ${order?.ID}`} />
                <div className="grid grid-cols-12">
                  <div className="col-span-5">From Company</div>
                  <div className="col-span-7">{order.FromCompanyID}</div>
                  <div className="col-span-5">To Company</div>
                  <div className="col-span-7">{order.ToCompanyID}</div>
                  <div className="col-span-5">Status</div>
                  <div className="col-span-7">{order.Status}</div>
                  <div className="col-span-5">Total</div>
                  <div className="col-span-7">${order.Total}</div>
                  {lineItems.map((li, i) => (
                    <Fragment key={i}>
                      <div className="col-span-5 indent-1">Product</div>
                      <div className="col-span-7">{li.product.Name}</div>
                      <div className="col-span-5 indent-1">Quantity</div>
                      <div className="col-span-7">{li.quantity}</div>
                      {li.specs.map((sp, ii) => (
                        <Fragment key={ii}>
                          <div className="col-span-5 indent-2">{sp.specId}</div>
                          <div className="col-span-7">{sp.optionId}</div>
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                  <div className="col-span-5">Fulfillment</div>
                  <div className="col-span-7">{order.xp?.FulfillmentType}</div>

                  <div className="col-span-5">First Name</div>
                  <div className="col-span-7">
                    {order.BillingAddress?.FirstName}
                  </div>
                  <div className="col-span-5">Last Name</div>
                  <div className="col-span-7">
                    {order.BillingAddress?.LastName}
                  </div>
                  <div className="col-span-5">Address Line 1</div>
                  <div className="col-span-7">
                    {order.BillingAddress?.Street1}
                  </div>
                  <div className="col-span-5">City</div>
                  <div className="col-span-7">{order.BillingAddress?.City}</div>
                  <div className="col-span-5">State</div>
                  <div className="col-span-7">
                    {order.BillingAddress?.State}
                  </div>
                  <div className="col-span-5">Zip</div>
                  <div className="col-span-7">{order.BillingAddress?.Zip}</div>
                  <div className="col-span-5">Country</div>
                  <div className="col-span-7">
                    {order.BillingAddress?.Country}
                  </div>
                </div>
              </>
            )}
        </div>
        {order === null && processingOrderCreation === false && (
          <BottomButton
            buttonType="submit"
            buttonText="Create Order"
            onClickCallback={async () => {
              handleSubmit(createOrder, () => {
                setProcessingOrderCreation(false);
              });
            }}
          ></BottomButton>
        )}
        {order !== null &&
          billingAddressCreated === false &&
          processingOrderCreation === false && (
            <BottomButton
              buttonType="submit"
              buttonText="Add Customer Details"
              onClickCallback={async () => {
                handleSubmit(addAddress, () => {
                  setProcessingOrderCreation(false);
                });
              }}
            ></BottomButton>
          )}

        {order !== null &&
          showingProduct === false &&
          billingAddressCreated &&
          lineItemsCreated === false &&
          processingOrderCreation === false &&
          lineItems !== null &&
          lineItems.length > 0 && (
            <BottomButton
              buttonType="submit"
              buttonText="Complete Line Items"
              onClickCallback={async () => {
                confirmAlert({
                  title: "Are you sure have added all the line items?",
                  buttons: [
                    {
                      label: "No, I need to add more.",
                    },
                    {
                      label: "Yes, I am sure.",
                      onClick: async () => {
                        addLineItems();
                      },
                    },
                  ],
                });
              }}
            ></BottomButton>
          )}
        {order !== null &&
          billingAddressCreated &&
          lineItemsCreated &&
          processingOrderCreation === false && (
            <BottomButton
              buttonType="submit"
              buttonText="Finish Order"
              onClickCallback={async () => {
                finishOrder();
              }}
            ></BottomButton>
          )}
      </form>
      <PoweredBy />
    </div>
  );
}
