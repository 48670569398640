import { Me, MeUser, Tokens } from "ordercloud-javascript-sdk";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import Cookie from "js-cookie";

export const GetMeWithCallback = async (
  callback: (me: MeUser<any>) => void,
  logging: boolean = false
) => {
  await Me.Get()
    .then((me) => {
      if (logging) {
        console.log(me);
      }
      if (typeof callback !== "undefined") {
        callback(me);
      } else {
        console.log("Invalid or no callback provided for GetMeWithCallback");
      }
    })
    .catch((err) => {
      console.log(err);
      MeFailureCallback();
    });
};

export const MeFailureCallback = () => {
  const navigate = useNavigate();
  confirmAlert({
    title: "Failed to get 'Me' details.",
    buttons: [
      {
        label: "Ok",
        onClick: async () => {
          Tokens.RemoveAccessToken();
          Cookie.remove("ordercloud.access-token");
          navigate("/");
          window.location.reload();
        },
      },
    ],
  });
};
