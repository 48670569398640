import { Order } from "ordercloud-javascript-sdk";
import SwipeToRevealActions from "react-swipe-to-reveal-actions";
import { OrderProps } from "../../types/orderProps";
import Icon from "react-icons-kit";
import moment from "moment";
import { OrderXp } from "../../types/OrderCloud/xp";
import {
  CancelOrder,
  CompleteOrder,
  CreateShipmentWithCallback,
  HoldOrder,
  SaveAssignedUserWithNote,
  UnHoldOrder,
  UpdateDeliveryStatusWithCallback,
} from "../../lib/orderHelper";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { unlock } from "react-icons-kit/feather/unlock";
import { stopCircle } from "react-icons-kit/feather/stopCircle";
import { deleteIconic } from "react-icons-kit/feather/deleteIconic";
import { lock } from "react-icons-kit/feather/lock";

export default function OrderComponent(props: OrderProps): JSX.Element {
  const workflowState = (
    order: Order<OrderXp, any, any>,
    clickAction: (id:string) => void
  ): JSX.Element => {
    if (props.lockedOrders.indexOf(order?.ID ?? "") > -1) {
      return (
        <div
          className="px-1 py-1 font-semibold text-sm bg-grey-300 text-white rounded-sm shadow-sm"
          style={{ width: "70px" }}
        >
          <span>
            <svg
              aria-hidden="true"
              className="w-1 h-1 mr-2 ml-1 text-white animate-spin dark:text-white fill-orange text-center"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </span>
        </div>
      );
    }
    if (
      typeof order.xp === "undefined" ||
      order.xp === null ||
      typeof order.xp.Status === "undefined" ||
      order.xp.Status === "NotStarted"
    ) {
      return (
        <span
          className="px-1 py-1 font-semibold text-sm bg-orange text-white rounded-sm shadow-sm block text-center w-notification cursor-pointer"
          onClick={()=>{clickAction(order.ID??"")}}
        >
          Not Started
        </span>
      );
    }

    if (
      typeof order.xp !== "undefined" &&
      order.xp !== null &&
      typeof order.xp.Status !== "undefined" &&
      order.xp.Status === "Completed"
    ) {
      return (
        <span
          className="px-1 py-1 font-semibold text-sm bg-green-200 text-white rounded-sm shadow-sm block text-center w-notification cursor-pointer"
          onClick={()=>{clickAction(order.ID??"")}}
        >
          Completed
        </span>
      );
    }

    if (
      typeof order.xp !== "undefined" &&
      order.xp !== null &&
      typeof order.xp.Status !== "undefined" &&
      order.xp.Status === "OnHold"
    ) {
      return (
        <span
          className="px-1 py-1 font-semibold text-sm bg-grey-400  text-white rounded-sm shadow-sm block text-center w-notification cursor-pointer"
          onClick={()=>{clickAction(order.ID??"")}}
        >
          On-Hold
        </span>
      );
    }

    if (
      typeof order.xp !== "undefined" &&
      order.xp !== null &&
      typeof order.xp.Status !== "undefined" &&
      order.xp.Status === "Cancelled"
    ) {
      return (
        <span
          className="px-1 py-1 font-semibold text-sm bg-red text-white rounded-sm shadow-sm block text-center w-notification cursor-pointer"
          onClick={()=>{clickAction(order.ID??"")}}
        >
          Cancelled
        </span>
      );
    }

    if (
      typeof order.xp !== "undefined" &&
      order.xp !== null &&
      typeof order.xp.Status !== "undefined" &&
      order.xp.Status === "Picked" &&
      order.xp.FulfillmentType === "Delivery" &&
      order.xp.Delivery !== "OutForDelivery" &&
      order.xp.Delivery !== "Delivered"
    ) {
      return (
        <span
          className="px-1 py-1 font-semibold text-sm bg-black text-white rounded-sm shadow-sm block text-center w-notification cursor-pointer"
          onClick={()=>{clickAction(order.ID??"")}}
        >
          Ready for Delivery
        </span>
      );
    }

    if (
      typeof order.xp !== "undefined" &&
      order.xp !== null &&
      typeof order.xp.Status !== "undefined" &&
      order.xp.Status === "Picked" &&
      order.xp.FulfillmentType === "PickUp"
    ) {
      return (
        <span
          className="px-0 py-1 font-semibold text-sm bg-black text-white rounded-sm shadow-sm block text-center w-notification cursor-pointer"
          onClick={()=>{clickAction(order.ID??"")}}
        >
          Complete for Pickup
        </span>
      );
    }

    if (
      typeof order.xp !== "undefined" &&
      order.xp !== null &&
      typeof order.xp.Status !== "undefined" &&
      order.xp.Status === "Picked" &&
      order.xp.FulfillmentType === "Delivery" &&
      order.xp.Delivery === "OutForDelivery"
    ) {
      return (
        <span
          className="px-1 py-1 font-semibold text-sm bg-purple-100 text-white rounded-sm shadow-sm block text-center w-notification cursor-pointer"
          onClick={()=>{clickAction(order.ID??"")}}
        >
          Out for Delivery
        </span>
      );
    }

    return (
      <span
        className="px-1 py-1 font-semibold text-sm bg-blue-200 text-white rounded-sm shadow-sm block text-center w-notification cursor-pointer"
        onClick={()=>{clickAction(order.ID??"")}}
      >
        In Progress
      </span>
    );
  };

  const getButtons = (): Array<{
    content: React.ReactNode;
    onClick: () => void;
  }> => {
    if (
      props.lockedOrders.indexOf(props.currentOrder?.ID ?? "") > -1 ||
      props.currentOrder.Status !== "Open"
    ) {
      return [];
    }

    const results = Array<{ content: React.ReactNode; onClick: () => void }>();

    switch (props.currentOrder.Status) {
      case "Open":
        if (
          typeof props.currentOrder.xp?.Assignment !== "undefined" &&
          props.currentOrder.xp?.Assignment?.AssignedTo === props.me?.ID
        ) {
          results.push({
            content: (
              <div
                className="table overflow-hidden w-full ml-1 pr-1"
                style={{ height: "140px" }}
              >
                <div className="text-white font-bold rounded-sm bg-orange hover:bg-orange focus:outline-none focus:shadow-black table-cell align-middle">
                  <span className="flex justify-around items-center">
                    <Icon className="" icon={unlock} size={12}></Icon>
                  </span>
                  <span>Unassign</span>
                </div>
              </div>
            ),
            onClick: async () =>
              confirmAlert({
                title: "Are you sure you want to unassign yourself?",
                buttons: [
                  {
                    label: "No, nevermind",
                  },
                  {
                    label: "Yes, I am sure",
                    onClick: async () =>
                      await SaveAssignedUserWithNote(
                        props.currentOrder,
                        props.currentOrder.xp?.Assignment?.AssignedTo ===
                          props.me?.ID
                          ? undefined
                          : props.me?.ID ?? undefined,
                        `${props.me?.FirstName ?? ""} ${
                          props.me?.LastName ?? ""
                        }`,
                        props.lockedOrders,
                        props.orders,
                        props.me,
                        props.setLockedOrders,
                        props.setOrders
                      ),
                  },
                ],
              }),
          });

          if (props.currentOrder.xp.Status !== "OnHold") {
            results.push({
              content: (
                <div
                  className="table overflow-hidden w-full ml-1 pr-1"
                  style={{ height: "140px" }}
                >
                  <div className="text-white font-bold rounded-sm bg-grey-400 hover:bg-grey-400 focus:outline-none focus:shadow-black table-cell align-middle">
                    <span className="flex justify-around items-center">
                      <Icon className="" icon={stopCircle} size={12}></Icon>
                    </span>
                    <span>Put On Hold</span>
                  </div>
                </div>
              ),
              onClick: async () =>
                confirmAlert({
                  title: "Are you sure you want to put this order on-hold?",
                  buttons: [
                    {
                      label: "No, nevermind",
                    },
                    {
                      label: "Yes, I am sure",
                      onClick: async () => {
                        await HoldOrder(
                          props.currentOrder?.ID ?? "",
                          props.me,
                          (order: Order<OrderXp, any, any>) => {
                            props.setOrders(
                              props.orders.map((o) => {
                                return o.ID === order.ID ? order : o;
                              })
                            );
                          },
                          () => {
                            confirmAlert({
                              title: "Failed to put order on-hold",
                              buttons: [
                                {
                                  label: "Ok",
                                  onClick: async () => {},
                                },
                              ],
                            });
                          }
                        );
                      },
                    },
                  ],
                }),
            });
          } else {
            results.push({
              content: (
                <div
                  className="table overflow-hidden w-full ml-1 pr-1"
                  style={{ height: "140px" }}
                >
                  <div className="text-white font-bold rounded-sm bg-blue-200 hover:bg-blue-200 focus:outline-none focus:shadow-black table-cell align-middle">
                    <span className="flex justify-around items-center">
                      <Icon className="" icon={stopCircle} size={12}></Icon>
                    </span>
                    <span>Take off Hold</span>
                  </div>
                </div>
              ),
              onClick: async () =>
                confirmAlert({
                  title:
                    "Are you sure you want to put this order back in-progress?",
                  buttons: [
                    {
                      label: "No, nevermind",
                    },
                    {
                      label: "Yes, I am sure",
                      onClick: async () => {
                        await UnHoldOrder(
                          props.currentOrder?.ID ?? "",
                          props.me,
                          (order: Order<OrderXp, any, any>) => {
                            props.setOrders(
                              props.orders.map((o) => {
                                return o.ID === order.ID ? order : o;
                              })
                            );
                          },
                          () => {
                            confirmAlert({
                              title: "Failed to put order on-hold",
                              buttons: [
                                {
                                  label: "Ok",
                                  onClick: async () => {},
                                },
                              ],
                            });
                          }
                        );
                      },
                    },
                  ],
                }),
            });
          }

          if (
            typeof props.currentOrder.xp?.Delivery === "undefined" &&
            props.currentOrder.xp?.FulfillmentType === "PickUp" &&
            props.currentOrder.xp?.Status === "Picked"
          ) {
            results.push({
              content: (
                <div
                  className="table overflow-hidden w-full ml-1 pr-1"
                  style={{ height: "140px" }}
                >
                  <div className="text-white font-bold rounded-sm bg-green-200 hover:bg-green-200 focus:outline-none focus:shadow-black table-cell align-middle">
                    <span className="flex justify-around items-center">
                      <Icon className="" icon={stopCircle} size={12}></Icon>
                    </span>
                    <span>Complete</span>
                  </div>
                </div>
              ),
              onClick: async () =>
                confirmAlert({
                  title: "Are you sure you complete this order?",
                  buttons: [
                    {
                      label: "No, nevermind",
                    },
                    {
                      label: "Yes, I am sure",
                      onClick: async () => {
                        await CompleteOrder(
                          props.currentOrder?.ID ?? "",
                          props.me,
                          (order: Order<OrderXp, any, any>) => {
                            props.setOrders(
                              props.orders.map((o) => {
                                return o.ID === order.ID ? order : o;
                              })
                            );
                          },
                          () => {
                            confirmAlert({
                              title: "Failed to complete the order",
                              buttons: [
                                {
                                  label: "Ok",
                                },
                              ],
                            });
                          }
                        );
                      },
                    },
                  ],
                }),
            });
          }

          if (
            typeof props.currentOrder.xp?.Delivery === "undefined" &&
            props.currentOrder.xp?.FulfillmentType === "Delivery" &&
            props.currentOrder.xp?.Status === "Picked"
          ) {
            results.push({
              content: (
                <div
                  className="table overflow-hidden w-full ml-1 pr-1"
                  style={{ height: "140px" }}
                >
                  <div className="text-white font-bold rounded-sm bg-purple-100 hover:bg-purple-100 focus:outline-none focus:shadow-black table-cell align-middle">
                    <span className="flex justify-around items-center">
                      <Icon className="" icon={stopCircle} size={12}></Icon>
                    </span>
                    <span>Out for Delivery</span>
                  </div>
                </div>
              ),
              onClick: async () =>
                confirmAlert({
                  title:
                    "Are you sure you mark this order as out for delivery?",
                  buttons: [
                    {
                      label: "No, nevermind",
                    },
                    {
                      label: "Yes, I am sure",
                      onClick: async () => {
                        await UpdateDeliveryStatusWithCallback(
                          props.currentOrder?.ID ?? "",
                          "OutForDelivery",
                          props.me,
                          (order: Order<OrderXp, any, any>) => {
                            props.setOrders(
                              props.orders.map((o) => {
                                return o.ID === order.ID ? order : o;
                              })
                            );
                          },
                          () => {
                            confirmAlert({
                              title:
                                "Failed to put mark order as out for delivery",
                              buttons: [
                                {
                                  label: "Ok",
                                  onClick: async () => {},
                                },
                              ],
                            });
                          }
                        );
                      },
                    },
                  ],
                }),
            });
          }

          if (
            props.currentOrder.xp?.Delivery === "OutForDelivery" &&
            props.currentOrder.xp?.FulfillmentType === "Delivery" &&
            props.currentOrder.xp?.Status === "Picked"
          ) {
            results.push({
              content: (
                <div
                  className="table overflow-hidden w-full ml-1 pr-1"
                  style={{ height: "140px" }}
                >
                  <div className="text-white font-bold rounded-sm bg-green-200 hover:bg-green-200 focus:outline-none focus:shadow-black table-cell align-middle">
                    <span className="flex justify-around items-center">
                      <Icon className="" icon={stopCircle} size={12}></Icon>
                    </span>
                    <span>Delivered</span>
                  </div>
                </div>
              ),
              onClick: async () =>
                confirmAlert({
                  title: "Are you sure you mark this order as delivered?",
                  buttons: [
                    {
                      label: "No, nevermind",
                    },
                    {
                      label: "Yes, I am sure",
                      onClick: async () => {
                        await UpdateDeliveryStatusWithCallback(
                          props.currentOrder?.ID ?? "",
                          "Delivered",
                          props.me,
                          async (order: Order<OrderXp, any, any>) => {
                            await CreateShipmentWithCallback(
                              props?.currentOrder?.ID ?? "",
                              async (order: Order<OrderXp, any, any>) => {
                                props.setOrders(
                                  props.orders.map((o) => {
                                    return o.ID === order.ID ? order : o;
                                  })
                                );
                              },
                              () => {
                                confirmAlert({
                                  title:
                                    "Failed to put mark order as out for delivery",
                                  buttons: [
                                    {
                                      label: "Ok",
                                      onClick: async () => {},
                                    },
                                  ],
                                });
                              }
                            );
                          },
                          () => {
                            confirmAlert({
                              title:
                                "Failed to put mark order as out for delivery",
                              buttons: [
                                {
                                  label: "Ok",
                                  onClick: async () => {},
                                },
                              ],
                            });
                          }
                        );
                      },
                    },
                  ],
                }),
            });
          }

          results.push({
            content: (
              <div
                className="table overflow-hidden w-full ml-1 pr-1"
                style={{ height: "140px" }}
              >
                <div className="text-white font-bold rounded-sm bg-red hover:bg-red focus:outline-none focus:shadow-black table-cell align-middle">
                  <span className="flex justify-around items-center">
                    <Icon className="" icon={deleteIconic} size={12}></Icon>
                  </span>
                  <span>Cancel</span>
                </div>
              </div>
            ),
            onClick: async () =>
              confirmAlert({
                title: "Are you sure you want to put this cancel this order?",
                buttons: [
                  {
                    label: "No, nevermind",
                  },
                  {
                    label: "Yes, I am sure",
                    onClick: async () => {
                      await CancelOrder(
                        props.currentOrder?.ID ?? "",
                        props.me,
                        (order: Order<OrderXp, any, any>) => {
                          props.setOrders(
                            props.orders.map((o) => {
                              return o.ID === order.ID ? order : o;
                            })
                          );
                        },
                        () => {
                          confirmAlert({
                            title: "Failed to put cancel order",
                            buttons: [
                              {
                                label: "Ok",
                                onClick: async () => {},
                              },
                            ],
                          });
                        }
                      );
                    },
                  },
                ],
              }),
          });
        } else if (
          typeof props.currentOrder.xp?.Assignment === "undefined" ||
          props.currentOrder.xp?.Assignment?.AssignedTo === null ||
          props.currentOrder.xp?.Assignment?.AssignedTo === ""
        ) {
          results.push({
            content: (
              <div
                className="table overflow-hidden w-full ml-1 pr-1"
                style={{ height: "140px" }}
              >
                <div className="text-white font-bold rounded-sm bg-blue-100 hover:bg-blue-300 focus:outline-none focus:shadow-black table-cell align-middle">
                  <span className="flex justify-around items-center">
                    <Icon className="" icon={lock} size={12}></Icon>
                  </span>
                  <span>Assign to Me</span>
                </div>
              </div>
            ),
            onClick: async () =>
              await SaveAssignedUserWithNote(
                props.currentOrder,
                props.currentOrder.xp?.Assignment?.AssignedTo === props.me?.ID
                  ? undefined
                  : props.me?.ID ?? undefined,
                `${props.me?.FirstName ?? ""} ${props.me?.LastName ?? ""}`,
                props.lockedOrders,
                props.orders,
                props.me,
                props.setLockedOrders,
                props.setOrders
              ),
          });
        } else {
          results.push({
            content: (
              <div
                className="table overflow-hidden w-full ml-1 pr-1"
                style={{ height: "140px" }}
              >
                <div className="text-white font-bold rounded-sm bg-red hover:bg-red focus:outline-none focus:shadow-black table-cell align-middle">
                  <span className="flex justify-around items-center">
                    <Icon className="" icon={lock} size={12}></Icon>
                  </span>
                  <span>Assign to Me</span>
                </div>
              </div>
            ),
            onClick: async () =>
              confirmAlert({
                title:
                  "Are you sure you want to assign this already assigned task to yourself?",
                buttons: [
                  {
                    label: "No, nevermind",
                  },
                  {
                    label: "Yes, I am sure",
                    onClick: async () =>
                      await SaveAssignedUserWithNote(
                        props.currentOrder,
                        props.currentOrder.xp?.Assignment?.AssignedTo ===
                          props.me?.ID
                          ? undefined
                          : props.me?.ID ?? undefined,
                        `${props.me?.FirstName ?? ""} ${
                          props.me?.LastName ?? ""
                        }`,
                        props.lockedOrders,
                        props.orders,
                        props.me,
                        props.setLockedOrders,
                        props.setOrders
                      ),
                  },
                ],
              }),
          });
        }
    }
    return results;
  };

  const clickAction = (id:string) => {
    props.onContinueOrder(id);
  };

  return (
    <>
      <SwipeToRevealActions
        hideDotsButton={true}
        actionButtons={getButtons()}
        actionButtonMinWidth={80}
        height="210px"
      >
        <div
          className={`w-full rounded-lg bg-white border-1 cursor-move border-black pt-2 pb-2`}
        >
          <div className=" mr-1 ml-1 grid grid-cols-7">
            <div className="col-span-7 mb-1">
              {workflowState(props.currentOrder, clickAction)}
            </div>
            <div className="text-right col-span-2 whitespace-nowrap ">
              Order #:
            </div>
            <div className="text-left whitespace-nowrap col-span-5 ml-2">
              {props.currentOrder.ID}
            </div>
            <div className="text-right col-span-2 whitespace-nowrap ">
              Created:
            </div>
            <div className="text-left whitespace-nowrap col-span-5 ml-2">
              {moment
                .utc(props.currentOrder.DateCreated)
                .local()
                .startOf("seconds")
                .fromNow()}
            </div>
            <div className="text-right col-span-2 whitespace-nowrap ">
              Phone number:
            </div>
            <div className="text-left whitespace-nowrap col-span-5 ml-2">
              {props.currentOrder.BillingAddress?.Phone ??
                props.currentOrder.FromUser?.Phone ??
                ""}
            </div>
            <div className="text-right col-span-2 whitespace-nowrap ">
              Email:
            </div>
            <div className="text-left whitespace-nowrap col-span-5 ml-2">
              {props.currentOrder.FromUser?.Email}
            </div>
            <div className="text-right col-span-2 whitespace-nowrap ">
              Customer:
            </div>
            <div className="text-left whitespace-nowrap col-span-5 ml-2">
              {props.currentOrder.BillingAddress?.FirstName ??
                props.currentOrder.FromUser?.FirstName ??
                ""}{" "}
              {props.currentOrder.BillingAddress?.LastName ??
                props.currentOrder.FromUser?.LastName ??
                ""}
            </div>
            <div className="text-right col-span-2 whitespace-nowrap ">
              Delivery method:
            </div>
            <div className="text-left whitespace-nowrap col-span-5 ml-2">
              {props.currentOrder?.xp?.FulfillmentType === "Delivery"
                ? "Delivery"
                : "Pick Up"}
            </div>
            {props.currentOrder?.xp?.FulfillmentType === "Delivery" &&
              (props.currentOrder?.xp?.Delivery === "OutForDelivery" ||
                props.currentOrder?.xp?.Delivery === "Delivered") && (
                <>
                  <div className="text-right col-span-2 whitespace-nowrap ">
                    Delivery status:
                  </div>
                  <div className="text-left whitespace-nowrap col-span-5 ml-2">
                    {props.currentOrder?.xp?.Delivery === "Delivered"
                      ? "Delivered"
                      : "Out for Delivery"}
                  </div>
                </>
              )}
            {(props.currentOrder.xp?.Assignment?.AssignedTo ?? null) !==
              null && (
              <>
                <div className="text-right col-span-2 whitespace-nowrap ">
                  Assigned To:
                </div>
                <div className="text-left whitespace-nowrap col-span-5 ml-2">
                  {props.currentOrder.xp?.Assignment?.AssignedTo ===
                  props.me?.ID
                    ? "Me"
                    : props.currentOrder.xp?.Assignment?.FullName}
                </div>
              </>
            )}
          </div>
        </div>
      </SwipeToRevealActions>
    </>
  );
}
