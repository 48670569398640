import { BottomButtonProps } from "../../types/bottomButtonProps";

export default function BottomButton(props: BottomButtonProps): JSX.Element {
  return (
    <button
      className={` text-white font-bold py-2 px-4 rounded bg-black hover:bg-black cursor-pointer w-full`}
      type={props.buttonType??'button'}
      onClick={async () => {
        props.onClickCallback();
      }}
    >
      <div className="w-full h-2">{props.buttonText}</div>
    </button>
  );
}
