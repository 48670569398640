import { FirstLetterOfEachWord } from "../../lib/textHelps";
import { AssignedToProps } from "../../types/assignedToProps";

export default function AssignedTo(props: AssignedToProps): JSX.Element {
  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8 relative z-50 justify-between py-2 bg-blue-200 text-white font-bold grid grid-cols-8 min-w-full text-2xl ">
      <div className="col-span-1 rounded-full bg-gradient-to-r from-green-100 via-purple-100 to-orange h-3 w-3 text-center ">
        <span
          className="relative uppercase"
          style={{ top: "5px" }}
        >{`${FirstLetterOfEachWord(props.fullName, 2)}`}</span>
      </div>
      <div
        className="col-span-7 font-bold relative text-left"
        style={{ top: "5px" }}
      >{props.fullName!==""?`Order Assigned to ${props.fullName}`:"Order Unassigned"}</div>
    </div>
  );
}
