import { TabButtonProps, TabType } from "../../types/tabButtonProps";

export default function TabButton(props: TabButtonProps): JSX.Element {
  const buttonText = (tabType: TabType) => {
    switch (tabType) {
      case "All":
        return props.tabText ?? "Orders";
      case "Assigned":
        return props.tabText ?? "Assigned";
      case "OnHold":
        return props.tabText ?? "On Hold";
      case "Short":
      case "Full":
        return props.tabText ?? tabType;
      case "InProgress":
        return props.tabText ?? "In Progress";
      default:
        return props.tabText ?? "Completed";
    }
  };
  return (
    <button
      className={`font-bold py-1 px-0 rounded col-span-2 text-center w-6  ${
        props.currentTabType === props.tabType
          ? "bg-black hover:bg-black cursor-pointer text-white"
          : "bg-white hover:white cursor-pointer text-black"
      }`}
      type="button"
      onClick={() => {
        props.onTabClick(props.tabType);
      }}
    >
      <span>{buttonText(props.tabType)}</span>
      
    </button>
  );
}
