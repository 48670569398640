/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Header from "../../header";
import { Supplier } from "ordercloud-javascript-sdk";
import Footer from "../../footer";
import { home } from "react-icons-kit/feather/home";
import RoundButton from "../../shared/roundButton";
import { useNavigate } from "react-router-dom";
import { GetSuppliersWithCallback } from "../../../lib/supplierHelper";
import Title from "../../shared/title";

export default function Home(): JSX.Element {
  const navigate = useNavigate();
  const [suppliers, setSuppliers] = useState<Array<Supplier<any>> | null>(null);
  const [supplier] = useState<Supplier<any> | null>(null);

  useEffect(() => {
    const getSuppliers = async (): Promise<void> => {
      await GetSuppliersWithCallback(callback);
    };

    const callback = (suppliers: Array<Supplier<any>>) => {
      setSuppliers(suppliers);
      if (suppliers.length === 1) {
        navigate(`/store/${suppliers[0].ID}`);
      }
    };

    getSuppliers();
  }, [supplier]);

  const getSuppliersList = (): JSX.Element => {
    if (typeof suppliers === "undefined" || suppliers === null)
      return <>There's an issue</>;

    return (
      <>
        {suppliers.map((s, i) => {
          return (
            <div key={i} className="mb-1 mt-1">
              <RoundButton
                url={`/store/${s.ID}`}
                text={s.Name}
                icon={home}
                iconColor="text-white"
                iconTextColor="bg-blue-300"
              />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="flex min-h-full flex-col">
      <Header title="Home" />
      <div className="ml-2 min-h-fit mb-4 ">
        {typeof suppliers !== "undefined" && suppliers !== null && (
          <>
            <Title title="Suppliers" />
            {getSuppliersList()}
          </>
        )}
      </div>
      <Footer selected="Home" />
    </div>
  );
}
