import Icon from "react-icons-kit";
import { HeaderProps } from "../types/headerProps";
import { logOut } from "react-icons-kit/feather/logOut";
import { Tokens } from "ordercloud-javascript-sdk";
import Cookie from "js-cookie";
import { chevronLeft } from "react-icons-kit/feather/chevronLeft";
import { Link } from "react-router-dom";

export default function Header(props: HeaderProps): JSX.Element {
  const handleLogOut = () => {
    Tokens.RemoveAccessToken();
    Cookie.remove("ordercloud.access-token");
    window.location.pathname = "/";
  };

  return (
    <header>
      <nav>
        <div className="mx-auto  px-4 sm:px-6 lg:px-8 relative z-50 justify-between py-4 bg-black text-white font-bold text-center ">
          {typeof props.backLink !== "undefined" && (
            <span className="float-left ">
              <Link to={props.backLink}>
                <Icon icon={chevronLeft} size={14} />
              </Link>
            </span>
          )}
          <span className="text-xl ">{props.title}</span>
          {Tokens.GetAccessToken() && (
            <span
              className="flex justify-around items-center float-right "
              onClick={handleLogOut}
            >
              <Icon
                className="cursor-pointer"
                icon={logOut}
                size={14}
                title="Logout"
              />
            </span>
          )}
        </div>
      </nav>
    </header>
  );
}
