import { useState } from "react";
import { NoteProps } from "../../types/noteProps";
import Icon from "react-icons-kit";
import { SaveNoteToOrderXp } from "../../lib/orderHelper";
import { edit } from "react-icons-kit/feather/edit";
import { Order } from "ordercloud-javascript-sdk";
import { OrderXp } from "../../types/OrderCloud/xp";

export default function Notes(props: NoteProps): JSX.Element {
  let noteField: HTMLTextAreaElement | null = null;
  const [newNote, setNewNote] = useState<boolean>(false);
  return (
    <>
      <div className="flex">
        {!newNote && (
          <span
            className="flex justify-around items-center"
            onClick={() => noteField?.focus()}
          >
            <Icon
              className="absolute -mr-3 mt-0 cursor-pointer"
              icon={edit}
              size={14}
            />
          </span>
        )}

        <textarea
          className={`shadow appearance-none border rounded-sm w-full text-grey-400 focus:outline-none focus:shadow-outline ${
            newNote ? "px-1 py-1" : "px-3 py-2"
          }`}
          id="note"
          rows={newNote ? 5 : 1}
          cols={200}
          placeholder={newNote ? "" : "Write a note..."}
          name="note"
          ref={(input) => {
            noteField = input;
          }}
          onFocus={() => setNewNote(true)}
          onBlur={() => {
            if (noteField?.value.length === 0) {
              setNewNote(false);
            }
          }}
        />
      </div>
      {newNote && (
        <button
          className={` text-white font-bold py-2 px-4 rounded-lg bg-black hover:bg-black cursor-pointer w-full mt-1`}
          type="button"
          onClick={async () => {
            if (
              typeof noteField !== "undefined" &&
              noteField !== null &&
              noteField?.value !== null &&
              noteField?.value !== ""
            ) {
              await SaveNoteToOrderXp(
                noteField.value,
                props.orderId,
                (o: Order<OrderXp, any, any>) => {
                  props.setOrder(o);
                }
              );
              noteField.value = "";
              noteField.blur();
              setNewNote(false);
            }
          }}
        >
          <div className="w-full h-2">Finish Note</div>
        </button>
      )}

      {props.notes !== null &&
        props.notes
          ?.map((note, i) => {
            return (
              <div key={i}>
                <textarea
                  className="shadow appearance-none border rounded-sm w-full py-1 px-1 text-grey-400 focus:outline-none focus:shadow-outline mt-1"
                  rows={typeof props.take !== "undefined" ? 2 : 5}
                  cols={200}
                  value={note}
                  readOnly={true}
                />
              </div>
            );
          })
          .reverse()
          .splice(
            0,
            typeof props.take !== "undefined" ? props.take : props.notes.length
          )}
    </>
  );
}
