export function FirstLetterOfEachWord(
  value: string | undefined,
  maxCount?: number
): string {
  if (typeof value === "undefined" || value === null) return "";
  const matches = value.match(/\b(\w)/g) ?? Array<string>();

  const result = matches.join("");
  return typeof maxCount === "undefined" || result.length < maxCount
    ? result
    : result.slice(0, maxCount); // JSON
}

export function Capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
