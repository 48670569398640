import { Tokens } from "ordercloud-javascript-sdk";
import Icon from "react-icons-kit";
import { home } from "react-icons-kit/feather/home";
import { search } from "react-icons-kit/feather/search";
import { save } from "react-icons-kit/feather/save";
import { settings } from "react-icons-kit/feather/settings";
import { FooterProps } from "../types/footerProps";
import { Link } from "react-router-dom";

export default function Footer(props: FooterProps): JSX.Element {
  return (
    <footer>
      <div className="mx-auto px-4 sm:px-6 lg:px-8 relative z-50 justify-between py-4 bg-white text-black border-t-2 border-grey-400 ">
        <div className="grid grid-cols-12 ">
          <Link to={"/"} className="col-span-3">
            <div
              className={`text-center ${
                props.selected === "Home" ? "text-black" : "text-grey-400"
              }`}
            >
              <span className="cursor-pointer grid">
                <Icon icon={home} size={24} />
                <span className="text-2xl mt-1">
                  {Tokens.GetAccessToken() ? "Home" : "Log In"}
                </span>
              </span>
            </div>
          </Link>
          <Link to={!Tokens.GetAccessToken() ? "/?redirect=search" : "/search"} className="col-span-3">
            <div
              className={`text-center  ${
                props.selected === "Search" ? "text-black" : "text-grey-400"
              }`}
            >
              <span className="cursor-pointer grid">
                <Icon icon={search} size={24} />
                <span className="text-2xl mt-1">Search</span>
              </span>
            </div>
          </Link>
          <Link to={"/"} className="col-span-3">
            <div
              className={`text-center ${
                props.selected === "Saved" ? "text-black" : "text-grey-400"
              }`}
            >
              <span className="cursor-pointer grid">
                <Icon icon={save} size={24} />
                <span className="text-2xl mt-1">Saved</span>
              </span>
            </div>
          </Link>
          <Link to={"/"} className="col-span-3">
            <div
              className={`text-center ${
                props.selected === "Settings" ? "text-black" : "text-grey-400"
              }`}
            >
              <span className="cursor-pointer grid">
                <Icon icon={settings} size={24} />
                <span className="text-2xl mt-1">Settings</span>
              </span>
            </div>
          </Link>
        </div>
      </div>
    </footer>
  );
}
