/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MeUser, Order, Shipment, Supplier } from "ordercloud-javascript-sdk";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "../../../../footer";
import Header from "../../../../header";
import Icon from "react-icons-kit";
import { search as searchIcon } from "react-icons-kit/feather/search";
import { refreshCw as refresh } from "react-icons-kit/feather/refreshCw";

import { OrderXp, ShippingXp } from "../../../../../types/OrderCloud/xp";
import { OrderToShipmentsMap } from "../../../../../types/OrderCloud/mappings";
import {
  GetOrderOutForDeliveryWithCallback,
  GetOrderShipmentsWithCallback,
} from "../../../../../lib/orderHelper";
import { GetSuppliersWithCallback } from "../../../../../lib/supplierHelper";
import { GetMeWithCallback } from "../../../../../lib/meHelper";
import Title from "../../../../shared/title";
import OrderComponent from "../../../../shared/orderComponent";

export default function ConfirmDelivery(): JSX.Element {
   const [selected, setSelected] = useState<boolean>(false);
  const [lockedOrders, setLockedOrders] = useState<Array<string>>(
    Array<string>()
  );
  const [me, setMe] = useState<MeUser<any> | null>(null);
  const [supplier, setSupplier] = useState<Supplier<any> | null>(null);
  const [shipments, setShipments] = useState<Array<OrderToShipmentsMap> | null>(
    null
  );
  const [search, setSearch] = useState("");
  const params = useParams();
  const navigate = useNavigate();

  const [orders, setOrders] = useState<Array<Order<OrderXp, any, any>> | null>(
    null
  );

  const handleSearch = () => {
    setOrders(null);
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    const getData = async (id: string): Promise<void> => {
      await GetOrderOutForDeliveryWithCallback(
        id,
        selected,
        search,
        me,
        callback
      );
    };

    const getShipping = async (order: Order<OrderXp, any, any>) => {
      await GetOrderShipmentsWithCallback(order.ID ?? "", shipmentsCallback);
    };

    const getSuppliers = async (): Promise<void> => {
      await GetSuppliersWithCallback(suppliersCallback, params.store);
    };

    const getMe = async (): Promise<void> => {
      await GetMeWithCallback(meCallback);
    };

    const suppliersCallback = (suppliers: Array<Supplier<any>>) => {
      if (suppliers.length > 0) {
        setSupplier(suppliers[0]);
        getData(suppliers[0].ID ?? "");
      } else {
        navigate("/notfound");
      }
    };

    const meCallback = (me: MeUser<any>) => {
      setMe(me);
    };

    const callback = (orders: Array<Order<any, any, OrderXp>>) => {
      setShipments(null);
      orders.forEach(async (o) => {
        await getShipping(o);
      });

      setOrders(orders);
    };

    const shipmentsCallback = (
      orderId: string,
      shipmentItems: Array<Shipment<ShippingXp, any, any>>
    ) => {
      const shipmentsArray = shipments ?? Array<OrderToShipmentsMap>();
      shipmentsArray.push({ OrderId: orderId ?? "", Shipments: shipmentItems });
      setShipments(shipments);
    };

    if (typeof me === "undefined" || me === null) {
      getMe();
    }
    if (typeof supplier === "undefined" || supplier === null) {
      getSuppliers();
    } else {
      if (typeof orders === "undefined" || orders === null) {
        getData(supplier.ID ?? "");
      }
    }
  }, [supplier, orders]);



  const onSelectedChange = () => {
    const sel = !selected;
    setSelected(sel);
    setOrders(null);
  };

  const onContinueOrder = (id:string) => {
      navigate(`/store/${supplier?.ID}/${id}`);
  };

  if (supplier === null) return <></>;
  return (
    <div className="flex min-h-screen flex-col">
      <Header title="Confirm Delivery" backLink={`/store/${supplier.ID}`} />
      <div className="ml-2 min-h-fit mb-4 mr-2">
        <Title title={supplier.Name} />

        <div className="flex">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-400 leading-tight focus:outline-none focus:shadow-outline max-h-2 mt-1"
            id="search"
            type="text"
            placeholder="Search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            onKeyDown={handleKeyDown}
          />
          <span
            className="flex justify-around items-center"
            onClick={handleSearch}
          >
            <Icon
              className="absolute mr-5 mt-1 cursor-pointer"
              icon={searchIcon}
              size={14}
            />
          </span>
        </div>
        <div className="mt-2 ">
          <span>
            <input
              type="checkbox"
              id="selected"
              name="selected"
              value={selected ? "selected" : ""}
              onChange={() => onSelectedChange()}
            />
            <label htmlFor="selected"> My Orders</label>
          </span>
          <span
            className="float-right cursor-pointer"
            onClick={() => {
              setOrders(null);
            }}
          >
            <Icon icon={refresh} size={14} /> Refresh
          </span>
        </div>
        <div className="mt-2">
          <>
          {orders!==null&& orders.length ===0 && (
            <>
            <img src={"/assets/img/empty.gif"} ></img>
            <div className="w-full text-center text-lg">It’s looking a little empty in here!</div>
            </>
          )}
          {orders!==null && orders?.map((o, i) => {
            const shipmentsArray =
              shipments?.find((s) => {
                return s.OrderId === o.ID;
              })?.Shipments ?? null;
            const shipment =
              shipmentsArray !== null && shipmentsArray?.length > 0
                ? shipmentsArray[0]
                : null;
            return (
              <OrderComponent
                currentOrder={o}
                lockedOrders={lockedOrders}
                me={me}
                orders={orders}
                setLockedOrders={setLockedOrders}
                setOrders={setOrders}
                shipment={shipment}
                key={i}
                onContinueOrder={onContinueOrder}
              ></OrderComponent>
            );
          })}</>
        </div>
      </div>
      <Footer selected="Home" />
    </div>
  );
}
