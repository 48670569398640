/* eslint-disable react-hooks/exhaustive-deps */
import { Tokens } from "ordercloud-javascript-sdk";
import { useEffect } from "react";
import Cookie from "js-cookie";

export default function AppLogOut({ children }: { children: any }) {
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];
  let timer: any;
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, 600000); // 600000ms = 10mins. You can change the time.
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    if (Tokens.GetAccessToken()) {
      Object.values(events).forEach((item) => {
        window.addEventListener(item, () => {
          resetTimer();
          handleLogoutTimer();
        });
      });
    }
  }, []);

  const logoutAction = () => {
    localStorage.clear();
    Tokens.RemoveAccessToken();
    Cookie.remove("ordercloud.access-token");
    window.location.pathname = "/";
  };

  return children;
}
