/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Supplier } from "ordercloud-javascript-sdk";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "../../../../footer";
import Header from "../../../../header";
import RoundButton from "../../../../shared/roundButton";
import { layers } from "react-icons-kit/feather/layers";
import { thumbsUp } from "react-icons-kit/feather/thumbsUp";
import { cornerDownLeft } from "react-icons-kit/feather/cornerDownLeft";
import { bag } from "react-icons-kit/ionicons/bag";
import { printer } from "react-icons-kit/ionicons/printer";
import { GetSuppliersWithCallback } from "../../../../../lib/supplierHelper";
import Title from "../../../../shared/title";

export default function Store(): JSX.Element {
  const [supplier, setSupplier] = useState<Supplier<any> | null>(null);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getSuppliers = async (): Promise<void> => {
      await GetSuppliersWithCallback(callback, params.store);
    };

    const callback = (suppliers: Array<Supplier<any>>) => {
      if (suppliers.length > 0) {
        setSupplier(suppliers[0]);
      } else {
        navigate("/");
      }
    };

    getSuppliers();
  }, [supplier]);
  if (supplier === null) return <></>;
  return (
    <div className="flex min-h-full flex-col">
      <Header title="Home" backLink="/" />
      <div className="ml-2 min-h-fit mb-4 ">
        <Title title={supplier.Name} subTitle="Customer Pickups" />
        <RoundButton
          icon={layers}
          text="Prepare Orders"
          url={`/store/${supplier.ID}/prepare-orders`}
          iconColor="text-white"
          iconTextColor="bg-blue-300"
        />
        <RoundButton
          icon={thumbsUp}
          text="Confirm Orders"
          url={`/store/${supplier.ID}/confirm-orders`}
          iconColor="text-white"
          iconTextColor="bg-blue-300"
        />
        <RoundButton
          icon={cornerDownLeft}
          text="Return to Shelf"
          url={`/store/${supplier.ID}/return-to-shelf`}
          iconColor="text-white"
          iconTextColor="bg-blue-300"
        />
        <RoundButton
          icon={bag}
          text="Confirm Delivery"
          url={`/store/${supplier.ID}/confirm-delivery`}
          iconColor="text-white"
          iconTextColor="bg-blue-300"
        />
        <Title subTitle="Document Reprint" subTitleMargin="mt-2" />
        <RoundButton
          icon={printer}
          text="Document Reprint"
          url={`/store/${supplier.ID}/document-reprint`}
          iconColor="text-black"
          iconTextColor="bg-yellow"
        />
      </div>
      <Footer selected="Home" />
    </div>
  );
}
