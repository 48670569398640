/* eslint-disable react-hooks/exhaustive-deps */
import {
  LineItem,
  MeUser,
  Order,
  Product,
  Supplier,
  Spec,
} from "ordercloud-javascript-sdk";
import { useEffect, useState } from "react";
import {
  LineItemXp,
  OrderXp,
  VariantXp,
} from "../../../../../../../types/OrderCloud/xp";
import { GetMeWithCallback } from "../../../../../../../lib/meHelper";
import {
  DeleteLineItemWithCallback,
  GetOrderByIdWithCallback,
  GetOrderLineItemWithCallback,
  SaveNoteToOrderXp,
  UpdateLineItemQtyWithCallback,
  UpdateLineItemWithCallback,
} from "../../../../../../../lib/orderHelper";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../../../header";
import { confirmAlert } from "react-confirm-alert";
import Title from "../../../../../../shared/title";
import { GetSupplierWithCallback } from "../../../../../../../lib/supplierHelper";
import LineItemComponent from "../../../../../../shared/lineItemComponent";
import {
  GetProductByIdWithCallback,
  GetProductSpecs,
} from "../../../../../../../lib/productHelper";
import Footer from "../../../../../../footer";
import BottomButton from "../../../../../../shared/bottomButton";
import { Capitalize } from "../../../../../../../lib/textHelps";

export default function Short(): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();

  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [me, setMe] = useState<MeUser<any> | null>(null);
  const [order, setOrder] = useState<Order<OrderXp, any, any> | null>(null);
  const [specs, setSpecs] = useState<Array<Spec<any, any>> | null>(null);
  const [product, setProduct] = useState<Product<any> | null>(null);
  const [supplier, setSupplier] = useState<Supplier<any> | null>(null);
  const [selectValue, setSelectValue] = useState<string>("1");
  const [originalSelectValue, setOriginalSelectValue] = useState<string>("1");
  const [selectedSpecs, setSelectedSpecs] = useState<
    Array<{ specId: string; optionId: string }>
  >(Array<{ specId: string; optionId: string }>());
  const [originalSpecs, setOriginalSpecs] = useState<
    Array<{ specId: string; optionId: string }>
  >(Array<{ specId: string; optionId: string }>());
  const [lineItem, setLineItem] = useState<LineItem<
    LineItemXp,
    any,
    VariantXp
  > | null>(null);
  useEffect(() => {
    const getData = async (): Promise<void> => {
      await GetOrderByIdWithCallback(params.order, callback, failureCallback);
    };

    const getSupplier = async (): Promise<void> => {
      await GetSupplierWithCallback(
        supplierCallback,
        supplierFailureCallback,
        params.store
      );
    };

    const supplierCallback = (supplier: Supplier<any>) => {
      setSupplier(supplier);
    };

    const supplierFailureCallback = () => {
      confirmAlert({
        title: "Unable to find the supplier.",
        buttons: [
          {
            label: "Ok",
            onClick: async () =>
              navigate(`/store/${params.store}/${params.order}`),
          },
        ],
      });
    };

    const getMe = async (): Promise<void> => {
      await GetMeWithCallback(meCallback);
    };

    const getLineItem = async () => {
      await GetOrderLineItemWithCallback(
        params.lineItem ?? "",
        params.order ?? "",
        async (lineItem: LineItem<LineItemXp, any, VariantXp>) => {
          setLineItem(lineItem);
          setSelectValue(lineItem.Quantity?.toString() ?? "0");
          setOriginalSelectValue(lineItem.Quantity?.toString() ?? "0");
          setSelectedSpecs(Array<{ specId: string; optionId: string }>());
          const mySpecs = Array<{ specId: string; optionId: string }>();
          // eslint-disable-next-line array-callback-return
          lineItem?.Specs?.map((s) => {
            if (
              typeof s.SpecID !== "undefined" &&
              typeof s.OptionID !== "undefined"
            ) {
              mySpecs.push({ specId: s.SpecID, optionId: s.OptionID });
            }
          });
          setSelectedSpecs(mySpecs);
          setOriginalSpecs(mySpecs);

          await GetProductByIdWithCallback(
            lineItem.ProductID,
            productCallback,
            productFailureCallback
          );
        },
        lineItemFailureCallback
      );
    };

    const productCallback = async (product: Product<any>) => {
      if (
        typeof product.Inventory === "undefined" ||
        typeof product.Inventory.QuantityAvailable === "undefined"
      ) {
        confirmAlert({
          title: "Product does not have any inventory.",
          buttons: [
            {
              label: "Ok",
              onClick: async () =>
                navigate(`/store/${params.store}/${params.order}`),
            },
          ],
        });
      } else {
        setProduct(product);
        await GetProductSpecs(
          product?.ID ?? "",
          specCallBack,
          specsFailureCallback
        );
      }
    };

    const specCallBack = (specs: Array<Spec<any, any>>) => {
      setSpecs(specs);
    };

    const specsFailureCallback = () => {
      confirmAlert({
        title: "Unable to find the product specs.",
        buttons: [
          {
            label: "Ok",
            onClick: async () =>
              navigate(`/store/${params.store}/${params.order}`),
          },
        ],
      });
    };

    const productFailureCallback = () => {
      confirmAlert({
        title: "Unable to find the product.",
        buttons: [
          {
            label: "Ok",
            onClick: async () =>
              navigate(`/store/${params.store}/${params.order}`),
          },
        ],
      });
    };

    const failureCallback = () => {
      confirmAlert({
        title: "Unable to find the order.",
        buttons: [
          {
            label: "Ok",
            onClick: async () =>
              navigate(`/store/${params.store}/${params.order}`),
          },
        ],
      });
    };

    const lineItemFailureCallback = () => {
      confirmAlert({
        title: "Unable to find the line item.",
        buttons: [
          {
            label: "Ok",
            onClick: async () =>
              navigate(`/store/${params.store}/${params.order}`),
          },
        ],
      });
    };

    const meCallback = (me: MeUser<any>) => {
      setMe(me);
    };

    const callback = async (order: Order<OrderXp, any, any>) => {
      if (
        order?.xp?.Assignment?.AssignedTo !== me?.ID &&
        order?.Status !== "Open"
      ) {
        confirmAlert({
          title: "You cannot modify the quantity on this line item.",
          buttons: [
            {
              label: "Ok",
              onClick: async () =>
                navigate(`/store/${params.store}/${params.order}`),
            },
          ],
        });
      } else {
        setOrder(order);
      }
    };

    if (typeof me === "undefined" || me === null) {
      getMe();
    }

    if (typeof lineItem === "undefined" || lineItem === null) {
      getLineItem();
    }

    if (typeof order === "undefined" || order === null) {
      getData();
    }

    if (typeof supplier === "undefined" || supplier === null) {
      getSupplier();
    }
  }, [order, lineItem, product, specs, selectedSpecs, showSpinner]);

  if (lineItem === null) return <></>;

  if (lineItem.xp?.Picked) {
    confirmAlert({
      title: "You cannot change the quantity on an already picked line",
      buttons: [
        {
          label: "Ok",
          onClick: async () =>
            navigate(`/store/${params.store}/${params.order}`),
        },
      ],
    });
  }

  const getSelectItems = (): JSX.Element[] => {
    if (
      typeof product?.Inventory === "undefined" ||
      typeof product?.Inventory.QuantityAvailable === "undefined"
    ) {
      return [<option value={lineItem?.Quantity}>{lineItem?.Quantity}</option>];
    }
    const options = Array<JSX.Element>();

    for (
      let index = 0;
      index <= product.Inventory.QuantityAvailable + (lineItem?.Quantity ?? 0);
      index++
    ) {
      options.push(<option value={index}>{index}</option>);
    }

    return options;
  };

  const UpdateLineItemQuantity = async (value: string) => {
    if (value === "0") {
      confirmAlert({
        title: "Are you sure you want to completely remove the line item?",
        buttons: [
          {
            label: "No, nevermind.",
            onClick: async () => {},
          },
          {
            label: "Yes, I am sure.",
            onClick: async () => {
              setSelectValue(value);
              await DeleteLineItemWithCallback(
                order?.ID ?? "",
                lineItem?.ID ?? "",
                async () => {
                  confirmAlert({
                    title: "The line item has been removed.",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: async () => {
                          await SaveNoteToOrderXp(
                            `Line item ${
                              lineItem?.Product?.Name ?? ""
                            } removed from order ${params.order} by ${
                              me?.FirstName
                            } ${me?.LastName}`,
                            order?.ID ?? ""
                          );
                          navigate(`/store/${params.store}/${params.order}`);
                        },
                      },
                    ],
                  });
                },
                () => {
                  confirmAlert({
                    title:
                      "There was a problem trying to delete the line item.",
                    buttons: [
                      {
                        label: "Ok",
                        onClick: async () =>
                          navigate(`/store/${params.store}/${params.order}`),
                      },
                    ],
                  });
                }
              );
            },
          },
        ],
      });
    } else {
      await UpdateLineItemQtyWithCallback(
        order?.ID ?? "",
        lineItem?.ID ?? "",
        parseInt(value),
        async (line: LineItem<LineItemXp, any, VariantXp>) => {
          await SaveNoteToOrderXp(
            `Quantity for line item ${
              line.Product?.Name ?? ""
            } changed to ${value} by ${me?.FirstName} ${me?.LastName}`,
            order?.ID ?? ""
          );
          navigate(`/store/${params.store}/${params.order}`);
        },
        () => {
          confirmAlert({
            title:
              "There was a problem trying to update the quantity on the line item.",
            buttons: [
              {
                label: "Ok",
                onClick: async () =>
                  navigate(`/store/${params.store}/${params.order}`),
              },
            ],
          });
        }
      );
      setSelectValue(value);
    }
  };

  const GetQuantityButtons = (
    qty: number,
    max: number,
    top: boolean = true
  ) => {
    const buttons = Array<JSX.Element>();
    const minStart = qty - 3 < 1 ? 1 : qty - 3;
    const maxStart = qty + 1 > max ? max : qty + 1;
    const maxEnd = qty + 4 > max ? max : qty + 4;
    if (top) {
      for (let index = minStart; index < qty; index++) {
        buttons.push(
          <button
            disabled={showSpinner}
            className={` text-white font-bold py-1 px-2 rounded col-span-1 mb-1 mr-1 ${
              !showSpinner
                ? "bg-blue-100 hover:bg-blue-300 focus:outline-none focus:shadow-black"
                : "bg-grey-200 hover:bg-grey-200 cursor-not-allowed  border-grey-200"
            }`}
            type="button"
            onClick={async () => {
              if (showSpinner) return;
              else await UpdateLineItemQuantity(index.toString());
            }}
          >
            <div>Set Quantity to {index}</div>
          </button>
        );
      }
    } else {
      for (let index = maxStart; index < maxEnd; index++) {
        buttons.push(
          <button
            disabled={showSpinner}
            className={`text-white font-bold py-1 px-2 rounded col-span-1 mb-1 mr-1 ${
              !showSpinner
                ? "bg-blue-200 hover:bg-blue-300 focus:outline-none focus:shadow-black"
                : "bg-grey-200 hover:bg-grey-200 cursor-not-allowed  border-grey-200"
            }`}
            type="button"
            onClick={async () => {
              if (showSpinner) return;
              else await UpdateLineItemQuantity(index.toString());
            }}
          >
            <div>Set Quantity to {index}</div>
          </button>
        );
      }
    }

    return buttons;
  };

  if (lineItem === null) {
    confirmAlert({
      title: "Cannot find the line item",
      buttons: [
        {
          label: "Ok",
          onClick: async () =>
            navigate(`/store/${params.store}/${params.order}`),
        },
      ],
    });
  }

  if (lineItem.xp?.Picked) {
    confirmAlert({
      title: "You cannot change the quantity on an already picked line",
      buttons: [
        {
          label: "Ok",
          onClick: async () =>
            navigate(`/store/${params.store}/${params.order}`),
        },
      ],
    });
  }

  return (
    <div className="flex min-h-screen flex-col">
      <Header
        title="Short"
        backLink={`/store/${params.store}/${params.order}`}
      />
      <div className="ml-2 min-h-fit mb-4 mr-2">
        <Title
          title={supplier?.Name}
          subTitle={`Order: ${order?.ID} - ${lineItem.Product?.Name ?? ""} x ${
            lineItem.Quantity ?? ""
          }`}
        />
        <LineItemComponent listItem={lineItem} />

        <div className="mt-2"></div>
        <div className="border-black rounded-md border-1 p-1">
          <label htmlFor="quantitySelect" className="mt-5 text-xl font-bold">
            Quantity quick picks:
          </label>
          <div className="grid grid-cols-2 mt-1">
            {GetQuantityButtons(
              lineItem.Quantity ?? 0,
              product?.Inventory?.QuantityAvailable ?? 1
            )}
          </div>
          <div className="grid grid-cols-4 ">
            <div className="col-span-4 text-2xl text-center"></div>
            <button
              disabled={showSpinner}
              className={` text-black font-bold py-1 px-2 rounded col-span-4 mr-1 ${"bg-grey-200 hover:bg-grey-200 cursor-default  border-grey-200"}`}
              type="button"
            >
              <div className="leading-8">
                Current Quantity:{" "}
                <span className="font-bold border-black border-1 leading-4 p-1 ml-1">
                  {lineItem.Quantity}
                </span>
              </div>
            </button>
          </div>
          <label
            htmlFor="quantitySelect"
            className="mt-2 text-xl font-bold"
          ></label>
          <div className="grid grid-cols-2 mt-1">
            {GetQuantityButtons(
              lineItem.Quantity ?? 0,
              product?.Inventory?.QuantityAvailable ?? 1,
              false
            )}
          </div>
        </div>
        <div className="mt-2"></div>
        <label htmlFor="quantitySelect" className="mt-2 text-xl font-bold ">
          Quantity
        </label>
        <select
          disabled={showSpinner}
          id="quantitySelect"
          name="quantitySelect"
          className="bg-white border-b px-0 text-grey-400 focus:outline-none focus:shadow-outline block w-full p-2.5 dark:placeholder-black dark:black h-3 pl-0 mt-1"
          onChange={async (value) => {
            setSelectValue(value.target.value);
          }}
          value={selectValue}
        >
          {getSelectItems()}
        </select>
        <div className="mt-2"></div>
        {specs?.map((s, i) => {
          return (
            <div key={i}>
              <label
                htmlFor={`spec-${s.ID ?? ""}`}
                className="mt-5 text-xl font-bold"
              >
                {s.Name}
              </label>
              <select
                disabled={showSpinner}
                id={`spec-${s.ID ?? ""}`}
                className="bg-white border-b px-0 text-grey-400 focus:outline-none focus:shadow-outline block w-full p-2.5 dark:placeholder-black dark:black h-3 pl-0 mt-1"
                onChange={async (value) => {
                  const mySpecs = selectedSpecs.map((spec) => {
                    return spec.specId === s.ID
                      ? { specId: s.ID, optionId: value.target.value }
                      : spec;
                  });
                  setSelectedSpecs(mySpecs);
                }}
                value={
                  selectedSpecs.find((spec) => {
                    return spec.specId === s.ID;
                  })?.optionId
                }
              >
                {s.Options?.map((o, ii) => {
                  return (
                    <option key={ii} value={o.ID}>
                      {Capitalize(o.Value ?? "")}
                    </option>
                  );
                })}
              </select>
              <div className="mt-2"></div>
            </div>
          );
        })}

        {(selectedSpecs !== originalSpecs ||
          selectValue !== originalSelectValue) && (
          <button
            disabled={showSpinner}
            className={`text-black font-bold py-1 px-4 rounded-lg flex justify-center ${
              showSpinner
                ? "bg-grey-200 hover:bg-grey-200 cursor-not-allowed  border-grey-200 "
                : "bg-white hover:bg-white border-black cursor-pointer"
            } border-1 w-full mt-1`}
            type="button"
            onClick={async () => {
              if (showSpinner) return;
              setShowSpinner(true);
              if (selectValue === "0") {
                confirmAlert({
                  title:
                    "Are you sure you want to completely remove the line item?",
                  buttons: [
                    {
                      label: "No, nevermind.",
                      onClick: async () => {
                        setShowSpinner(false);
                      },
                    },
                    {
                      label: "Yes, I am sure.",
                      onClick: async () => {
                        setSelectValue(selectValue);
                        await DeleteLineItemWithCallback(
                          order?.ID ?? "",
                          lineItem?.ID ?? "",
                          async () => {
                            confirmAlert({
                              title: "The line item has been removed.",
                              buttons: [
                                {
                                  label: "Ok",
                                  onClick: async () => {
                                    await SaveNoteToOrderXp(
                                      `Line item ${
                                        lineItem?.Product?.Name ?? ""
                                      } removed from order ${params.order} by ${
                                        me?.FirstName
                                      } ${me?.LastName}`,
                                      order?.ID ?? ""
                                    );
                                    navigate(
                                      `/store/${params.store}/${params.order}`
                                    );
                                  },
                                },
                              ],
                            });
                          },
                          () => {
                            confirmAlert({
                              title:
                                "There was a problem trying to delete the line item.",
                              buttons: [
                                {
                                  label: "Ok",
                                  onClick: async () =>
                                    navigate(
                                      `/store/${params.store}/${params.order}`
                                    ),
                                },
                              ],
                            });
                          }
                        );
                      },
                    },
                  ],
                });
              } else {
                await UpdateLineItemWithCallback(
                  order?.ID ?? "",
                  product?.ID ?? "",
                  lineItem?.ID ?? "",
                  parseInt(selectValue),
                  selectedSpecs,
                  async (line: LineItem<LineItemXp, any, VariantXp>) => {
                    await SaveNoteToOrderXp(
                      `Specs and/or Quantity for line item ${
                        line.Product?.Name ?? ""
                      } updated by ${me?.FirstName} ${me?.LastName}`,
                      order?.ID ?? ""
                    );
                    navigate(`/store/${params.store}/${params.order}`);
                  },
                  () => {
                    confirmAlert({
                      title:
                        "There was a problem trying to update the line item.",
                      buttons: [
                        {
                          label: "Ok",
                          onClick: async () =>
                            navigate(`/store/${params.store}/${params.order}`),
                        },
                      ],
                    });
                  }
                );
              }
            }}
          >
            {showSpinner ? (
              <div className="">
                <svg
                  aria-hidden="true"
                  className="w-2 h-2 mr-2 ml-1 text-white animate-spin dark:text-white fill-orange text-center"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Please wait</span>
              </div>
            ) : (
              <div className="w-full h-2">Update</div>
            )}
          </button>
        )}
      </div>
      <BottomButton
        buttonText="Cancel"
        onClickCallback={() => {
          navigate(`/store/${params.store}/${params.order}`);
        }}
      ></BottomButton>
      <Footer selected="Home"></Footer>
    </div>
  );
}
