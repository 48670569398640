import { Link } from "react-router-dom";
import Header from "../header";
import { Tokens } from "ordercloud-javascript-sdk";
import Footer from "../footer";

export default function NotFound() {
  return (
    <div className="flex min-h-full flex-col">
      <Header title="Page Not Found" />
      <div className="py-4">
        <h1 className="px-8 text-lg mb-4">Oops...Page Not Found</h1>
        <Link to={"/"} className="px-8 text-lg mb-4">{Tokens.GetAccessToken()?"Dashboard":"Log In"}</Link>
      </div>
      <Footer selected="Home" />
    </div>
  );
}
