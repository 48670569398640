import { Capitalize } from "../../lib/textHelps";
import { LineItemComponentProps } from "../../types/lineItemComponentProps";

export default function LineItemComponent(
  props: LineItemComponentProps
): JSX.Element {
  const variant = props.listItem.Variant;
  return (
    <div className={`mt-3 grid grid-cols-12 ${props.additionalClasses??""}`}>
        {typeof props.quantity === "undefined" && (
           <div className="col-span-2 w-full"> </div>
        )}
      <div className="col-span-4">
        <img
          src={
            typeof variant?.xp?.Thumbnails !== "undefined" &&
            variant?.xp?.Thumbnails?.length > 0
              ? props.listItem.Variant?.xp?.Thumbnails[0]
              : ""
          }
          alt={variant?.Name}
        ></img>
      </div>
      <div
        className={`${"col-span-6" }`}
      >
        <div className="text-xl ml-1">{variant?.Description}</div>
        <ul className="list-outside mt-1 ml-1">
          <li className="grid grid-cols-12">
            <span className="text-grey-400 col-span-2 text-right">SKU:</span>
            <span className="col-span-10">{props.listItem.ProductID}</span>
          </li>
          <li className="grid grid-cols-12">
            <span className="text-grey-400 col-span-2 text-right">UPC:</span>
            <span className="col-span-10">{variant?.xp?.StyleNumber}</span>
          </li>
          <li className="grid grid-cols-12">
            <span className="text-grey-400 col-span-2 text-right">Brand:</span>
            <span className="col-span-10">{variant?.xp?.Brand}</span>
          </li>
          {typeof props.listItem.Specs !== "undefined" &&
            props.listItem.Specs !== null &&
            props.listItem.Specs?.length > 0 &&
            props.listItem.Specs?.map((s, i) => {
              return (
                <li className="grid grid-cols-12" key={i}>
                  <span className="text-grey-400 col-span-2 text-right">
                    {s.Name}:
                  </span>
                  <span className="col-span-10">{Capitalize(s.Value??"")}</span>
                </li>
              );
            })}
        </ul>
      </div>
      {props.quantity}
      {props.button}
    </div>
  );
}
